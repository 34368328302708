import * as Yup from 'yup'
import { AlertModal } from "../.."
import { useFormik } from "formik"
import { Button, CircularProgress } from "@mui/material"
import Field from './fields/Field'


export default function TestForm () {

    const formik = useFormik({
        initialValues: {
            checkbox: true,
            text: "",
            date: null,
            select: "",
            multipleSelect: [],
            files: []
        },
        validationSchema: Yup.object({
            checkbox: Yup.boolean().required("Please do the checkbox field"),
            text: Yup.string().required("Please do the text field"),
            date: Yup.date().min(new Date("12/11/1996"), "Must be after Phins birthday").nullable().required("Please do the date field"),
            select: Yup.string().required("Please do the select field"),
            multipleSelect: Yup.array().min(1).required("Please do the multipleSelect field"),
            files: Yup.mixed().test("files", "Maximum file size is 1MB", (files) => {
                if (!Array.isArray(files)) return false
                
                for (var i = 0; i < files.length; i++) {
                    console.log(files[i])
                    if (files[i].size > 1000000) {
                        return false
                    }
                }
                return true
            }).test("files", "Please provide a file", (files) => {
                if (!Array.isArray(files)) return false
                return files.length > 0
            }),
        }),
        onSubmit: async (values, actions) => {
            setTimeout(() => {
                AlertModal({body: JSON.stringify(formik, null, 2), code: true})
                return Promise.reject()
            }, 1000)
            return Promise.resolve()
        }
    })

    console.log("Demo form values", formik.values)

    return (
        <form className="form" onSubmit={formik.handleSubmit} style={{display: "grid", gridGap: "5px"}}>
            <h3>Formik form</h3>
            <div className="form-fields">
                <Field.Checkbox
                    formik
                    name="checkbox" 
                    value={formik.values.checkbox} 
                    warning={formik.submitCount ? formik.errors.checkbox : ""} 
                    label="Check mate" 
                    onChange={formik.handleChange} />

                <Field.Switch
                    formik
                    name="checkbox" 
                    value={formik.values.checkbox} 
                    warning={formik.submitCount ? formik.errors.checkbox : ""} 
                    leftLabel="Off"
                    label="On" 
                    onChange={formik.handleChange} />
                
                <Field.Text
                    formik
                    label="Test input"
                    name="text" 
                    // value={formik.values.text} 
                    warning={formik.submitCount ? formik.errors.text : ""} 
                    placeholder="Text field (uncontrolled)" 
                    onChange={formik.handleChange} />

                <Field.Text
                    formik
                    name="text" 
                    value={formik.values.text} 
                    warning={formik.submitCount ? formik.errors.text : ""} 
                    placeholder="Text field" 
                    onChange={formik.handleChange} />

                <Field.Date
                    formik
                    name="date" 
                    // value={formik.values.date} 
                    label="Date field (uncontrolled)"
                    warning={formik.submitCount ? formik.errors.date : ""} 
                    onChange={formik.handleChange} />

                {/* <div className="help-text">{formik.values.date?.toString()}</div>
                <div className="help-text">{formik.values.date?.}</div> */}
                
                <Field.Date
                    formik
                    name="date" 
                    value={formik.values.date} 
                    warning={formik.submitCount ? formik.errors.date : ""} 
                    onChange={formik.handleChange} />

                <Field.Select
                    formik
                    name="select" 
                    value={formik.values.select} 
                    warning={formik.submitCount ? formik.errors.select : ""} 
                    placeholder="Select something"
                    onChange={formik.handleChange}
                    options={[
                        {value: 1, label: "Option 1"},
                        {value: 2, label: "Option 2"}
                    ]} />

                <Field.MultipleSelect
                    formik
                    name="multipleSelect" 
                    value={formik.values.multipleSelect} 
                    warning={formik.submitCount ? formik.errors.multipleSelect : ""} 
                    placeholder="Select some things"
                    onChange={formik.handleChange}
                    options={[
                        {value: 1, label: "Option 1"},
                        {value: 2, label: "Option 2"}
                    ]} />

                <Field.FileMultiple
                    formik
                    label="Select a file"
                    name="files" 
                    value={formik.values.files} 
                    warning={formik.submitCount ? formik.errors.files : ""} 
                    placeholder="Select some things"
                    onChange={formik.handleChange}
                    />
            </div>

            <div className="form-buttons">
                <Button className="btn btn-sm" disabled={formik.isSubmitting} type="submit" color="primary" variant="contained">
                    {!formik.isSubmitting ? "Validate" : <CircularProgress />}
                </Button>
            </div>
        </form>
    )
}