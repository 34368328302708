import * as Yup from "yup"
import { useFormik } from "formik"
import Ajax from "../../../Ajax"
import { store } from "../../../stores/Store"
import { Alert } from "../../.."
import Field from "../fields/Field"
import { Button, CircularProgress } from "@mui/material"


interface FormValues {
    file: File | null;
}

interface FileWithMetadata extends File {
    type: string;
    size: number;
}

export default function LogoUpdate ({account}) {

    const formik = useFormik<FormValues>({

        initialValues: {
            file: null
        },

        validationSchema: Yup.object({
            file: Yup.mixed<FileWithMetadata>()
                .required('A file is required')
                .test('fileFormat', 'Only PNG image files are allowed', value => {
                    return value && value.type === 'image/png';
                })
                .test('fileSize', 'File size must be less than 300kb', value => {
                    return value && value.size <= 300000; // 3MB in bytes
                }),
        }),

        onSubmit: async (values, actions) => {
            var formData = new FormData()

            if (formik.values.file) {
                formData.append("file", formik.values.file)

                await Ajax.Questionnaire.UpdateAdvisorLogo(formData, account.advisorUid).catch((response) => {
                    Alert({message: response.data?.detail ? response.data.detail : "Failed to enable questionnaires"})
                })

                if (store.ToolStore.client?.uid === account.uid) {
                    store.ToolStore.getClient(account.uid)
                }

                if (store.ToolStore.account?.uid === account.uid) {
                    store.ToolStore.getAccount(account.uid)
                }

                store.AlertStore.Close()
            }
        }
    })

    return (
        <div className="form">
            <h2>Update Logo</h2>

            <p>The logo is used to brand the questionnaires that you send.</p>

            <Field.File
                formik
                name="file"
                onChange={(e) => formik.setFieldValue("file", e.target.value)}
                value={formik.values.file}
                warning={formik.submitCount ? formik.errors.file : null} />

            <div>
                <Button
                    className="btn btn-md"
                    variant="contained"
                    color="success"
                    onClick={() => formik.submitForm()}
                    disabled={(!formik.isValid && !!formik.submitCount) || formik.isSubmitting} >
                    Submit {formik.isSubmitting ? <CircularProgress color="info" style={{width: "8px", height: "8px"}} /> : null}
                </Button>
            </div>
        </div>
    )
}