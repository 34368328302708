import { AlertModal } from "../../.."
import { observer } from "mobx-react-lite"
import { store } from "../../../stores/Store"
import TruncText from "../../../misc/TruncText"
import InlineMenu from "../../../misc/InlineMenu"
import IsAllowed from "../../../models/Permissions"
import DeleteForm from "../../form/case/DeleteForm"
import { Button, CircularProgress } from "@mui/material"
import UpdatePasswordForm from "../../form/admin/UserPasswordUpdateForm"
import { UserCreateForm, UserUpdateForm } from "../../form/admin/UserForms"


interface AccountUsersProps {
    account: any;
}


export default observer(function AccountUsers ({account}: AccountUsersProps) {

    const users = account?.childrenObjects.filter((node: any) => node.typeName === "Aora.Platform.Data.UserNode")

    return (
        <div className="tool-section">
            <h3>Users</h3>
            
            <div className="tool-table">
                <p style={{display: "grid", gridTemplateColumns: "35px auto 100px 140px 140px"}}>
                    <span></span>
                    <span style={{minWidth: "100px"}}><b>Name</b></span>
                    <span><b>UID</b></span>
                </p>

                {users?.map((user, i) => <UserDetail key={i} user={user} />)}
            </div>
        </div>
    )
})

function UserDetail ({user}) {
    return (
        <p style={{display: "grid", gridTemplateColumns: "35px auto 100px 140px 140px"}}>
            <InlineMenu
                disabled={!!store.AppStore.sessionState}
                title={store.NodeStore.getDisplayType(user) + " options"}
                menuItems={[
                    {
                        label: "Update password",
                        action: () => AlertModal({body: <UpdatePasswordForm user={user} />, size: "xs"})
                    },
                    {
                        label: "Edit user",
                        action: () => AlertModal({body: <UserUpdateForm requestFullUser user={user} onSuccess={() => {
                            store.ToolStore.getAccount()
                            store.AlertStore.Close()
                        }} />}),
                        hidden: !IsAllowed(user, "UPDATE")
                    },
                    {
                        label: "Delete user",
                        action: () => AlertModal({body: <DeleteForm node={user} onSuccess={() => {
                            store.ToolStore.getAccount()
                            store.AlertStore.Close()
                        }} />}),
                        hidden: !IsAllowed(user, "DELETE")
                    },
            ]} />
            
            <TruncText text={user.label} style={{minWidth: "100px"}} />
            
            <TruncText text={user.uid} />
        </p>
    )
}