import Ajax from '../Ajax'
import { store } from './Store'
import { Theme } from '../misc/Theme'
import { Alert, DisplayValue } from '..'
import { makeAutoObservable } from 'mobx'
import { Automator } from '../models/Automator'


interface RunningAutomation {
    automator: Automator;
    enquiryUid: string;
}


export class AutomationStore {

    runningAutomation: RunningAutomation = null;
    options: any[] = null;

    constructor () {
        makeAutoObservable(this)
    }

    get enquiryAutomations () {
        var automations = []

        if (store.EnquiryStore.enquiry?.automations?.length) {
            automations = store.EnquiryStore.enquiry?.automations
        }

        return automations
    }

    reset = () => {
        this.runningAutomation = null
    }

    run = async (automationId, additionalData = "{}") => {
        await Ajax.Automation.Run(store.EnquiryStore.enquiry.uid, automationId, additionalData).catch((response) => {
            Alert({message: response.data?.detail ? response.data?.detail : "Failed to run automation"})
        })
    }

    cancel = async (id = this.runningAutomation.automator.id) => {
        await Ajax.Automation.Cancel(id).catch((response) => {
            Alert({message: response.data?.detail ? response.data?.detail : "Failed to cancel automation"})
        })
    }

    init = async () => {
        await Ajax.Automation.Options().then((response) => {
            this.options = response.data
        }).catch(() => {
            Alert({message: "Unable to get Automation options"})
        })
    }

    getQualifyingOnlineForms = (ruleId) => {

        if (!ruleId || !this.options?.length) { 
            return []
        }
        
        var onlineFormsOptions = this.options.filter(option => option.qualifying_rules?.includes(ruleId))

        // Skilled Worker
        if (ruleId === "UK.D.33.1") {
            var outcomeData = store.EnquiryStore.enquiry?.additionalOutcomeData
            
            if (outcomeData) {
                if (outcomeData && "applying_from_uk" in outcomeData && outcomeData["applying_from_uk"]) {
                    onlineFormsOptions = onlineFormsOptions.filter(option => {
                        return !["SWV_EC", "SWV_COS_APPLY", "SWV_COS_ASSIGN_D"].includes(option.id)
                    })
                }
                if (outcomeData && "applying_from_uk" in outcomeData && !outcomeData["applying_from_uk"]) {
                    onlineFormsOptions = onlineFormsOptions.filter(option => {
                        return !["SWV_FLR", "SWV_COS_ASSIGN"].includes(option.id)
                    })
                }
            }
        }

        return onlineFormsOptions
    }

    updateEnquiryAutomations = (enquiryUid, automator: Automator) => {
        if (store.EnquiryStore.enquiry?.uid === enquiryUid) {
            var existingItem = this.enquiryAutomations.find(a => a.automatorJson.includes(automator.id))

            if (existingItem) {
                existingItem.automatorJson = JSON.stringify(automator)
            } else {
                store.EnquiryStore.enquiry.automations.push({
                    "typeName": "Aora.Platform.Data.AutomationNode",
                    "parent": null,
                    "uid": automator.id.replace("AUTOMATOR", "AUTOMATION"),
                    "label": null,
                    "canEdit": true,
                    "canUpdate": true,
                    "canDelete": true,
                    automatorJson: JSON.stringify(automator)
                  })
            }
        }
    }

    handleAutomationNotification = async (message) => {

        console.log("AutomationNotification", message)

        var { leafNode } = store.NodeStore
        var { enquiryUid, automator } = message

        // Handle finished automations
        if (["INSTANTIATED", "RUNNING"].includes(automator.status)) {
            
            this.runningAutomation = message
        
        } else {
        
            if (leafNode.uid === enquiryUid) {
                await store.NodeStore.refreshLeafNode()
            } else {

                var label = DisplayValue("automation_status", automator.status)
                var color = this.statusColor(automator.status, true)

                Alert({
                    message: `${label}`,
                    persist: true,
                    color: color,
                    actions: [{label: "Go to Automation", color: "info", action: () => store.NodeStore.navigateNode(enquiryUid)}]
                })
            }

            this.runningAutomation = null
        }
    }

    statusColor = (status: string, mui: boolean = false) => {

        var color = "info"

        switch (status) {
            case "INSTANTIATED":
            case "RUNNING":
            case "CANCELLED":
                return mui ? "info" : Theme.palette.info.contrastText
            case "FINISHED":
                color = "success"
                break
            case "FAIL":
            case "ERROR":
            case "TIMEOUT":
            case "WEBSITE_ERROR":
            case "MAPPING_ERROR":
            case "VALIDATION_ERROR":
                color = "error"
                break
        }

        return mui ? color : Theme.palette[color].main
    }

    statusDescription = (automator: Automator) => {

        if (automator.status_message) {
            return automator.status_message
        }

        switch (automator.status) {
            case "INSTANTIATED":
                return "This automation is getting ready to start."

            case "RUNNING":
                return "This automation is running."

            case "FINISHED":
                return ""

            case "TIMEOUT":
                return "This automation was taking too long to run so it was cancelled."
    
            case "VALIDATION_ERROR":
                return "Some of your input data failed validation in the online form."
            
            case "WEBSITE_ERROR":
            case "HOME_OFFICE_ERROR":
                return "The target website of the automation is having some problems at the moment, please try again later."
    
            case "CANCELLED":
                return "This automation was cancelled."
    
            case "MAPPING_ERROR":
                return "There was an unexpected page which our software could not automate."
    
            case "ERROR":
                return "There was an unexpected error."
            
            default:
                return "Unkown status."
        }
    }
}