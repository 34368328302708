import React from 'react'
import Panel from '../../layout/Panel'
import { observer } from 'mobx-react-lite'
import { Icons } from '../../../misc/Icons'
import FolderPanelCta from './FolderPanelCta'
import { store } from '../../../stores/Store'
import PanelBody from '../../layout/PanelBody'
import PanelLabel from '../../layout/PanelLabel'
import PanelHeader from '../../layout/PanelHeader'
import FolderPanelListItem from "./FolderPanelListItem"
import { Button, CircularProgress } from '@mui/material'


export default observer(function FolderPanel () {

    var panelWidth = "320px"
    var { folderNode, loading } = store.NodeStore
    var classes = store.NodeStore.loading || !store.NodeStore.accounts ? "panel-folder disabled" : "panel-folder"
    var displayType = store.NodeStore.getDisplayType(folderNode)
    var displayName = store.NodeStore.getDisplayName(folderNode)

    return (
        <Panel index={0} id="FOLDER" className={classes} width={panelWidth} minimisable loadingContent={false}>

            <PanelLabel title={
                !loading && displayName
                    ? <div className="trunc-ellipsis">{`${displayType}: ${displayName}`}</div> 
                    : <CircularProgress style={{width: "10px", height: "10px", margin: 0}} />
            } />

            <PanelHeader showBackLink node={folderNode} children={!folderNode ? (
                <div style={{display: "grid", gap: "10px"}}>
                    <small className="panel-header-node-type"><Icons.Home /> Home folder</small>
                    <small>Navigate the list below to find your cases.</small>
                </div>
            ) : null} />

            <PanelBody>
                <FolderPanelList />

                <div style={{margin: "10px 0 15px 0"}}>
                    <FolderPanelCta />
                </div>
            </PanelBody>

        </Panel>
    )
})


const FolderPanelList = observer(function () {
    
    const { folderNode, homeNodeUid, getFolderChildren, getDisplayType } = store.NodeStore
    const folderChildren = getFolderChildren
    const nodesWithGroupedChildren = ["Aora.Platform.Data.AccountNode", "Aora.Nationality.Data.SponsorAccountNode", "Aora.Platform.Data.CaseNode", "Aora.Platform.Data.FolderNode"]
    const isSVWCase = folderChildren.find(node => node.typeName.includes("Sponsor"))
    const groupedNodes = {}

    folderChildren?.forEach((node: any) => {
        var label = getDisplayType(node, true)
        
        if (node.typeName.includes("Person") && isSVWCase) {
            label = "People (Job Applicants)"
        }

        if (node.uid === homeNodeUid) {
            label = node?.accountType === "sponsor" ? "Cases" : "Nationality"
        }

        if (node.typeName.includes("Case")) {
            label = "Cases" 
            if (folderNode?.accountType === "sponsor") { // Only way to know if case is skilled worker case
                label = "Cases (Skilled Worker Visa)"
            } else if (folderNode?.accountType) {
                label = "Cases (Nationality)"
            }
        }

        if (label in groupedNodes) {
            groupedNodes[label].push(node)
        } else {
            groupedNodes[label] = [node]
        }
    })

    if (folderNode === null || nodesWithGroupedChildren.includes(folderNode?.typeName)) return (
        <nav id="folder-panel-list" style={{marginTop: "6px"}}>
            {Object.keys(groupedNodes)?.map((groupName, i) => 
                <ListGroup key={`${folderNode?.uid}${i}}`} groupName={groupName} list={groupedNodes[groupName]} />
            )}
        </nav>
    )

    return (
        <nav id="folder-panel-list">
            <List list={folderChildren} />
        </nav>
    )
})


function ListGroup ({groupName, list}) {

    const [minimised, setMinimised] = React.useState(false)

    return (
        <>
            <div className="menu-divider">
                <Button onClick={() => setMinimised(!minimised)}>
                    {groupName}
                    <Icons.ArrowRight style={{fontSize: "16px", padding: "0", transform: minimised ? "rotate(0)" : "rotate(90deg)"}} />
                </Button>
            </div>
            
            {!minimised ? <List list={list} /> : <div style={{padding: "3px"}}></div>}
        </>
    )
}

function List ({list}) {
    return list?.map((node: any, i) => (
        <React.Fragment key={i}>
            <FolderPanelListItem node={node} />
            {i < list.length -1 ? <div style={{width: "auto", height: "0.5px", backgroundColor: "#c7c7c7", margin: "auto 8px"}}></div> : null}
        </React.Fragment>
    ))
}