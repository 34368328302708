import React from "react"
import AutomationMenuOptions from "./AutomationMenuOptions"
import AutomationReviewDetails from "./AutomationReviewDetails"
import AutomationResumeDetails from "./AutomationResumeDetails"
import ViewQuestionnaire from "../../../questionnaire_mgmt/ViewQuestionnaire"
import { Button, Tab, Tabs } from "@mui/material"
import { observer } from "mobx-react-lite"
import { Automator } from "../../../../../models/Automator"
import { store } from "../../../../../stores/Store"
import { AlertModal, DisplayValue } from "../../../../.."
import AutomationAnswers from "./AutomationAnswers"
import { Icons } from "../../../../../misc/Icons"


interface AutomationDetail {
    label: string;
    automator?: Automator;
}


export default observer(function AutomationDetail (props: AutomationDetail) {

    const [tab, setTab] = React.useState<"details" | "answers">("details")

    const {enquiryAutomations, runningAutomation} = store.AutomationStore
    var automator = props.automator 

    // The automator passed from props won't get updated, so check 'enquiryAutomations' and 'runningAutomation' for the updated automator.
    const enquiryAutomation = enquiryAutomations.find(a => a.automatorJson.includes(props.automator.id))
    
    if (runningAutomation && runningAutomation.automator?.id === automator.id) {
        automator = runningAutomation.automator
    } else if (enquiryAutomation) {
        automator = JSON.parse(enquiryAutomation.automatorJson)
    }

    if (!automator) return <h4>No automator</h4>

    return (        
        <div className="automation-review">
            <div className="header">
                
                <Tabs
                    value={tab}
                    textColor="primary"
                    indicatorColor="primary"
                    variant="fullWidth"
                    style={{width: "100%", borderBottom: "1px solid lightgrey"}}
                    className="automation-review-tab">
                    <Tab onClick={() => setTab("details")} value="details" label="Automation Details" />
                    <Tab onClick={() => setTab("answers")} value="answers" label="Answers" />
                </Tabs>
            </div>

            <div className="automation-review-columns">
                <div className={tab === "details" ? "automation-information" : "automation-information collapse"}>
                    <h2 className="collapse">Automation Review <AutomationMenuOptions automator={automator} /></h2>

                    <AutomationStatus automator={automator} />
                    
                    <div>
                        <h4>Input fields filled</h4>
                        <AutomationReviewDetails automator={automator} />
                    </div>
                    
                    {!["INSTANTIATED", "RUNNING"].includes(automator.status) ? (
                        <div>
                            <h4 style={{margin: "8px 0"}}>Resume details</h4>
                            <AutomationResumeDetails automator={automator} />
                        </div>
                    ) : null}
                    
                    {automator.downloads?.length ? (
                        <div>
                            <h4 style={{margin: "8px 0"}}>Downloads</h4>
                            <ul style={{margin: 0}}>
                                {automator.downloads.map((downloadJson, i) => {
                                    var download = JSON.parse(downloadJson)
                                    return (
                                        <li>
                                            <div style={{display: "flex", justifyContent: "space-between", justifyItems: "center"}}>
                                                <small>{download.field_name}</small>
                                                
                                                <Button 
                                                    className="btn btn-xs" 
                                                    onClick={() => downloadBase64File(download.binary, download.format, download.display_name)}>
                                                    <Icons.Download /> Download
                                                </Button>
                                            </div>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    ) : null}

                    {automator.profile.questionnaires?.length ? (
                        <div>
                            <h4 style={{margin: "8px 0"}}>Questionnaires used</h4>
                            
                            <ul style={{margin: 0}}>
                                {automator.profile.questionnaires.map((qRef, i) => (
                                    <li key={i}>
                                        <small
                                            className="link"
                                            onClick={() => AlertModal({width: "700px", body: <ViewQuestionnaire uid={qRef.value} />})} >
                                            {qRef.label}
                                        </small>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ) : null}
                </div>

                <div style={{overflow: "hidden"}} className={tab === "answers" ? "" : "collapse"}>
                    <AutomationAnswers automator={automator} />
                </div>
            </div>
        </div>
    )
})


function AutomationStatus ({automator}) {

    const { statusColor, statusDescription, cancel } = store.AutomationStore
    const [cancelling, setCancelling] = React.useState(false)

    var color = statusColor(automator.status)
    var description = statusDescription(automator)
    var status = DisplayValue("automation_status", automator.status)

    const onCancel = async () => {
        setCancelling(true)
        await cancel(automator.id)
    }

    return (
        <div style={{color: color}}>
            <div style={{display: "flex", alignItems: "center", columnGap: "10px"}}>
                <span>
                    <b>
                        {automator.status === "RUNNING" && cancelling ? "Cancelling..." : `${status},`} {Math.floor(((automator.current_page + 1) / automator.total_pages) * 100)}% complete
                    </b>
                </span>
                
                {["RUNNING", "INSTANTIATED"].includes(automator.status) ? (
                    <Button 
                        onClick={() => onCancel()} 
                        className="btn btn-xs" 
                        color="error" 
                        disabled={cancelling}
                        variant="contained">
                        Cancel
                    </Button>
                ) : null}
            </div>
            
            <div style={{marginLeft: "8px"}}>
                <small>{description}</small>

                {automator?.validation_messages?.length ? (
                    <div>
                        {automator?.validation_messages.map((message, i) => (
                            <small key={i} className="warning" style={{margin: "10px", display: "list-item"}}>{message}</small>
                        ))}
                    </div>
                ) : null}
            </div>
        </div>
    )
}



function downloadBase64File(base64String, mimeType, fileName) {
    
    const link = document.createElement('a');
    
    link.href = `data:${mimeType};base64,${base64String}`
    link.download = fileName;
    
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}