import * as Yup from 'yup'
import { useFormik } from "formik"
import { Button, CircularProgress } from "@mui/material"
import Ajax from '../../../Ajax'
import { store } from '../../../stores/Store'
import Field from '../fields/Field'
import React from 'react'


export default function LoginForm ({connectionOptions, initiateMfa}) {

    const [errorMessage, setErrorMessage] = React.useState(null)
    
    const formik = useFormik({

        initialValues: {
            username: "",
            password: "",
            connectionName: ""
        },

        validationSchema: Yup.object({
            username: Yup.string().required("Please give your username"),
            password: Yup.string().required("Please give your password"),
            connectionName: connectionOptions ? Yup.string().required("Please select a server to connect to") : Yup.string().nullable()
        }),

        onSubmit: async (values, actions) => {

            setErrorMessage(null)
            values.password = values.password.trim()

            return await Ajax.Session.Login(values).then(async (response) => {
                
                if (response.data.token) {
                
                    await store.AppStore.init(response.data.token)
                    store.AppStore.navigate("/app/main")
                
                } else if (response.data.mfaEmail) {
                    initiateMfa(response.data.mfaEmail, response.data.webSessionId, response.data.message)
                }
            
            }).catch((response) => {
                
                console.log(response)
                var errors: any = {}

                if (!response) {
                    setErrorMessage("Check your internet connection")
                } else if (response.data?.detail) {
                    setErrorMessage(response.data?.detail)
                } else if (response?.data?.errors) {
                    Object.keys(response?.data?.errors).forEach(key => errors[key] = String(response.data?.errors[key]))
                } else {
                    setErrorMessage("Something went wrong"  )
                }

                actions.setErrors(errors)
                
            })
        }
    })


    return (
        <form autoComplete="on" className="form form-login" onSubmit={formik.handleSubmit}>

            <h1 style={{fontSize: "30px"}}>Sign in</h1>

            <div className="form-fields">
                
                {connectionOptions ? (
                    <Field.Select
                        formik
                        name="connectionName"
                        label="Connection"
                        autoFocus={true}
                        autoComplete={true}
                        warning={formik.submitCount && formik.errors.connectionName ? formik.errors.connectionName : ""}
                        value={formik.values.connectionName}
                        onChange={formik.handleChange}
                        options={connectionOptions.map(connection => {
                            return {value: connection, label: connection}
                        })} />
                ) : null}

                <Field.Text
                    formik
                    name="username"
                    label="Username"
                    autoFocus={true}
                    autoComplete={true}
                    warning={formik.submitCount && formik.errors.username ? formik.errors.username : ""}
                    value={formik.values.username}
                    onChange={formik.handleChange} />

                <Field.Text
                    formik
                    type="password"
                    name="password"
                    label="Password"
                    autoComplete={true}
                    warning={formik.submitCount && formik.errors.password ? formik.errors.password : false}
                    value={formik.values.password}
                    onChange={formik.handleChange} />

                {errorMessage ? <div className="warning">{errorMessage}</div> : <></>}
            </div>

            <div className="form-footer">
                <Button className="btn-md" disabled={formik.isSubmitting} type="submit" color="primary" variant="contained">
                    {!formik.isSubmitting ? "Sign in" : <CircularProgress />}
                </Button>
            </div>
        </form>
    )
}