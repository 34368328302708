import React from "react"
import AutomationIdCheckForm, { AutomationIdCheckOTPForm } from "./AutomationIdCheckForm"
import AutomationDontSubmitSection from "./AutomationDontSubmitSection"
import AutomationEmailSection from "./AutomationEmailSection"
import { Button } from "@mui/material"


export default function AutomationConfirmFormSection ({
    automationOption,
    automationData,
    hidden = true,
    onCancel = () => {},
    onSubmit = (values) => {}
}) {

    if (hidden) return <></>

    if (automationOption.navigation_style === "cos_sms") {
        return (
            <AutomationDontSubmitSection 
                onSubmit={async (values) => await onSubmit(values)} 
                onCancel={onCancel} 
                automationOption={automationOption} />
        )
    }

    if (automationOption.navigation_style === "home_office_classic") {
        return (
            <AutomationEmailSection 
                onSubmit={async (values) => await onSubmit(values)} 
                onCancel={onCancel} 
                automationOption={automationOption} 
                automationData={automationData} />
        )
    }

    if (automationOption.navigation_style === "home_office_dashboard") {
        return (
            <HomeOfficeDashboardSection 
                onSubmit={async (values) => await onSubmit(values)} 
                onCancel={onCancel} 
                automationOption={automationOption} 
                automationData={automationData} />
        )
    }

    // Fall back to just a submit button, if navigation style is not recognised.
    return (
        <Button 
            className="btn btn-sm" 
            variant="contained" 
            color="success" 
            onClick={() => onSubmit({})}>
            Start {automationOption.navigation_style} automation
        </Button>
    )
}


function HomeOfficeDashboardSection ({
    automationOption,
    automationData,
    onCancel = () => {},
    onSubmit = async (values) => {}
}) {
    
    const [idCheckValues, setIdCheckValues] = React.useState(null)
    const [otpValues, setOtpValues] = React.useState(null)

    return (
        <>
            <AutomationIdCheckForm
                hidden={idCheckValues}
                onCancel={() => onCancel()}
                onSubmit={(values) => setIdCheckValues(values)} />

            <AutomationIdCheckOTPForm
                hidden={!idCheckValues || otpValues}
                onCancel={() => setIdCheckValues(null)}
                onSubmit={(values) => setOtpValues(values)} />

            <AutomationEmailSection 
                includeSubjectEmail={false}
                hidden={!idCheckValues || !otpValues}
                onSubmit={async (values) => await onSubmit({...idCheckValues, ...otpValues, ...automationData, ...values})} 
                onCancel={() => setOtpValues(null)} 
                automationOption={automationOption} 
                automationData={automationData} />
        </>
    )
}