import { AlertModal } from "../../.."
import { Button } from "@mui/material"
import { observer } from "mobx-react-lite"
import { Icons } from "../../../misc/Icons"
import { store } from "../../../stores/Store"
import { CaseCreateForm } from "../../form/case/CaseForms"
import { FolderCreateForm } from '../../form/folder/FolderForms'
import { NewSponsorClientAccountForm } from '../../form/admin/AccountForms'
import EnquiryCreateForm from '../../form/enquiry/create_form/EnquiryCreateForm'


export default observer(function FolderPanelCta () {

    const { folderNode, loading, getHomeNode} = store.NodeStore
    const empyCase = !folderNode?.childrenObjects?.filter((node) => !node.typeName.includes("Sponsor"))?.length

    if (store.AppStore.sessionState) return <></>

    if (!folderNode && !loading && !["sponsor", "client"].includes(getHomeNode?.accountType)) {
        return (
            <Button
                className="btn btn-sm"
                variant="contained"
                onClick={() => AlertModal({title: "Create new Sponsor", body: <NewSponsorClientAccountForm />})}>
                Create new Sponsor
            </Button>
        )
    }

    if (["Aora.Platform.Data.AccountNode", "Aora.Nationality.Data.SponsorAccountNode", "Aora.Platform.Data.FolderNode"].includes(folderNode?.typeName) && !loading) {

        var guidance = "We advise you to use the family name of the subject in the name of the case."

        if (folderNode.typeName === "Aora.Nationality.Data.SponsorAccountNode") {
            guidance = `This case will be associated with ${folderNode.label}, Skilled Worker enquiries will be available.`
        } else if (folderNode.typeName === "Aora.Platform.Data.AccountNode") { 
            guidance = "This case won't be associated with a Sponsor Client so skilled worker enquiries will not be possible."
        }

        return (
            <div style={{display: "flex", gap: "8px", marginTop: "10px"}}>
                <Button 
                    className="btn btn-sm" 
                    variant="contained" onClick={() => {
                        AlertModal({body: (
                            <FolderCreateForm 
                                parentUid={folderNode.uid} 
                                onSuccess={() => {
                                    store.AlertStore.Close()
                                    store.NodeStore.refreshFolderNode()
                            }} />
                        )})
                    }}>
                    Create Folder
                </Button>
                <Button 
                    className="btn btn-sm" 
                    variant="contained" onClick={() => {
                        AlertModal({body: (
                            <CaseCreateForm 
                                parentUid={folderNode.uid} 
                                guidance={guidance} 
                                onSuccess={(node) => {
                                    store.AlertStore.Close()
                                    store.NodeStore.navigateNode(node.uid)
                            }} />
                        )})
                    }}>
                    Create Case
                </Button>
            </div>
        )
    }

    if (["Aora.Platform.Data.CaseNode"].includes(folderNode?.typeName) && !loading) {

        return (
            <>
                {empyCase ? (
                    <div style={{display: "flex", alignItems: "center", gap: "4px"}}>
                        <Icons.Info style={{fontSize: "12px", color: "grey"}} />
                        <small>Create a new Person or Enquiry to get started</small>
                    </div>
                ) : null}

                <div style={{display: "flex", flexDirection: "column", gap: "8px", marginTop: "10px"}}>
                    <div style={{display: "flex", gap: "8px"}}>
                        <Button
                            variant="contained" 
                            className="btn btn-sm" 
                            onClick={() => store.QuestionStore.initForm("CREATE_PERSON")}
                            disabled={store.QuestionStore.initialisationForm === "CREATE_PERSON"}>
                            Create Person
                        </Button>

                        <Button
                            variant="contained" 
                            className="btn btn-sm" 
                            onClick={() => AlertModal({body: <EnquiryCreateForm caseUid={folderNode.uid} />, size: "auto"})}
                            disabled={store.QuestionStore.initialisationForm === "CREATE_PERSON"}>
                            Create Enquiry
                        </Button>
                    </div>
                </div>
            </>
        )
    }

    return <></>
})