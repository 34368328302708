import { Alert } from '..'
import Ajax from '../Ajax'
import { store } from './Store'
import { AxiosResponse } from 'axios'
import Account from '../models/Account'
import { makeAutoObservable } from 'mobx'
import { ErrorNode, NodeReference } from '../models/Interfaces'


export class NodeStore {

    homeNodeUid: string | null;
    folderNode: any | null;
    leafNode: any | null;
    accounts: Account[];
    accountUid: string;
    loadingLeafNode: boolean;
    loading: boolean;
    breadcrumb: NodeReference[];
    isEditing: boolean;

    constructor() {
        this.reset()
        makeAutoObservable(this)
    }

    init = () => {
        this.getAccounts()
        this.setHomeNodeUid(store.AppStore.getJwtClaim("account_uid"))
    }

    reset = () => {
        this.folderNode = null
        this.leafNode = null
        this.accounts = null
        this.accountUid = null
        this.loadingLeafNode = true
        this.loading = true
        this.breadcrumb = []
        this.isEditing = false
    }

    get account() {
        return this.accounts?.find(a => a.uid === this.accountUid)
    }

    get getHomeNode () {
        return this.accounts?.find(a => a.uid === this.homeNodeUid)
    }

    get getFolderChildren () {

        if (!store.AppStore.isLoggedIn) return []
        
        // if (!this.folderNode && this.homeNodeUid) return []

        if (!this.folderNode && this.accounts) {
            
            var homeAccount = this.accounts.find(a => a.uid === this.homeNodeUid)
            var clients = this.accounts.filter(a => a.parent?.uid === this.homeNodeUid)
            
            return [
                homeAccount,
                ...clients.filter(account => account.accountType === "sponsor"),
                ...clients.filter(account => account.accountType === "client")
            ]
        }

        if (!this.folderNode?.childrenObjects?.length) return []

        // First, sort alphabetically
        var children = [...this.folderNode.childrenObjects].sort((a, b) => a.label?.localeCompare(b.label))

        // Only show folders under accounts, order alphabetically
        if (this.folderNode.typeName?.includes("AccountNode") || this.folderNode.typeName?.includes("FolderNode")) {
            return [
                ...children.filter((child) => ["Aora.Nationality.Data.SponsorNode"].includes(child.typeName)),
                ...children.filter((child) => ["Aora.Platform.Data.FolderNode"].includes(child.typeName)),
                ...children.filter((child) => ["Aora.Platform.Data.CaseNode"].includes(child.typeName))
            ]
        }

        // Order People by date of birth, put sponsor first
        if (this.folderNode.typeName?.includes("CaseNode")) {
            return [
                ...children.filter(child => child.typeName.includes("Sponsor")),
                ...children.filter(child => child.typeName.includes("EnquiryNode")).sort((a, b) => {
                    return (a.subjects?.length && b.subjects?.length) ? a.subjects[0].name?.localeCompare(b.subjects[0].name) : true
                }),
                ...children.filter(child => child.typeName.includes("PersonNode")).sort((a, b) => b.dateOfBirth?.localeCompare(a.dateOfBirth))
            ]
        }

        return children
    }

    setHomeNodeUid = (uid: string) => {
        this.homeNodeUid = uid
    }
    
    setFolderNode = (node: any) => {
        // console.log("Setting folder node", node?.uid, node?.label)

        if (node === null) { // null folderNode will show the accounts list
            store.AppStore.currentPanel = "FOLDER"
            this.breadcrumb = []
        }

        this.folderNode = node
        this.isEditing = false
    }

    setLeafNode = (node: any) => {
        
        this.leafNode = node

        if (node?.typeName === "Aora.Nationality.Data.PersonNode" || node?.typeName === "Aora.Platform.Data.EnquiryNode") {
            if (node.caseNode && !window.location.href.includes(node.caseNode.uid)) {
                this.navigateNode(node.caseNode.uid)
            }
        }
    }

    updateOrEditProcedure = async (action: Function) => {
        try {
            if (store.QuestionStore.informationRequest) {
                throw new Error("You cannot edit two attributes at once")
            }
            if (!store.AppStore.sessionState) {
                await Ajax.Entity.BeginEdit(store.NodeStore.leafNode.uid)
            } else if (store.AppStore.stateSubject !== store.NodeStore.leafNode.uid) {
                throw new Error(`You cannot edit this while ${store.AppStore.sessionState}`)
            }
            await action().then(() => {
                store.NodeStore.refreshLeafNode()
            }).catch((response) => {
                Alert({message: response.data.detail, color: "error"})
            })
        }
        catch (e) {
            Alert({message: e.message})
        }
    }

    refreshFolderNode = async () => {
        if (this.folderNode) {
            // console.log("Refreshing folder node", this.folderNode.uid)
            await this.selectNode(this.folderNode.uid)
        }
        return this.folderNode
    }

    refreshLeafNode = async () => {
        if (this.leafNode) {
            await this.selectNode(this.leafNode.uid)   
        }
        return this.leafNode
    }

    getAccounts = async () => {

        var accounts = []

        await Ajax.Node.GetAccounts().then((response) => {
            accounts = response.data?.sort((a, b) => a.label.localeCompare(b.label))
        })

        this.accounts = accounts

        if (this.accountUid === null || !accounts.find(account => account.uid === this.accountUid)) {
            this.accountUid = store.AppStore.sessionInfo?.userAccount?.uid
        }
    }

    navigateNode = async (uid: string = null) => {

        if (!uid) {
            store.AppStore.navigate("/app/main")
        }

        var type = uid?.split("_")[0]

        switch (type) {

            case "ACCOUNT":
            case "FOLDER":
            case "CASE":

                if (!this.loading) { // Disable navigation when already loading node
                    var leafNodeUid = this.leafNode?.uid ? this.leafNode?.uid : ""
                    store.AppStore.navigate(`/app/main/${uid}/${leafNodeUid}`)
                }
                return

            case "SPONSOR":
            case "USER":
            case "ENTITY":
            case "PERSON":
            case "ENQUIRY":
                if (!this.loadingLeafNode) { // Disable navigation when already loading nodes
                    var folderNodeUid = this.folderNode?.uid ? this.folderNode.uid : ""
                    store.AppStore.navigate(`/app/main/${folderNodeUid}/${uid}`)
                }
                return
        }
    }


    handleUri = async (params: any) => {

        // Folder panel to fall back to this.homeNodeUid
        // var folderNodeUid = "folder_uid" in params ? params.folder_uid : this.homeNodeUid
        
        // Handle folder node
        if ("folder_uid" in params) {
            if (params.folder_uid !== this.folderNode?.uid) {
                await this.selectNode(params.folder_uid, !!store.AppStore.sessionState !== true)
            }
        } else {
            this.setFolderNode(null)
            this.loading = false
        }

        // Handle leaf node
        if ("leafnode_uid" in params) {
            await this.selectNode(params.leafnode_uid, !!store.AppStore.sessionState !== true)
        } else {
            this.setLeafNode(null)
            this.loadingLeafNode = false
        }
    }


    // All node navigation should be done by changing the URI and then calling selectNode from handleUri
    private selectNode = async (uid: string, focusPanel: boolean = true) => {

        var node: any

        // UID must be in '{type}_{id}' format
        if (uid?.split("_", 2).length < 2) { return }

        var type = uid?.split("_")[0]

        switch (type) {
            case "ACCOUNT":
            case "FOLDER":
            case "CASE":

                this.loading = true

                if (type === "ACCOUNT") {
                    this.accountUid = uid
                }

                await Ajax.Node.Get(uid).then((response) => {
                    node = response.data
                }).catch((response) => {
                    node = this.generateErrorNode(uid, response)
                })

                this.updateBreadcrumb(node)
                this.setFolderNode(node)

                store.AppStore.setPageTitle(this.getDisplayName(node))

                this.loading = false
                return Promise.resolve(node)

            case "SPONSOR":
            case "USER":
            case "ENTITY":
            case "PERSON":
            case "ENQUIRY":

                if (this.leafNode?.uid !== uid) {
                    this.loadingLeafNode = true
                }
                store.AppStore.currentPanel = "DETAIL"

                await Ajax.Node.Get(uid).then((response) => {
                    node = response.data
                }).catch((response) => {
                    node = this.generateErrorNode(uid, response)
                })

                this.setLeafNode(node)

                this.loadingLeafNode = false

                if (type === "ENQUIRY") {
                    store.ReportStore.checkForPendingReports()
                }

                // Scroll to lowest item in locatorPath which is displayed in the UI
                setTimeout(() => {
                    if (store.QuestionStore.locatorPath && Array.isArray(store.QuestionStore.locatorPath)) {
                        for (var i = 0; i < store.QuestionStore.locatorPath.length; i += 1) {
                            var n = store.QuestionStore.locatorPath.reverse()[i]
                            var highlightedElement = document.getElementById(n.uid)
                            if (highlightedElement) {
                                highlightedElement.scrollIntoView()
                                store.QuestionStore.highlightUid = n.uid
                                break
                            }
                        }
                    }
                }, 200)

                return Promise.resolve(node)

            default:
                Alert({ message: `Unexpected type: ${uid}` })
                return Promise.reject()

        }
    }


    // getAncestors = async (node, count = 20): Promise<NodeReferenceTree> => {

    //     var tree: NodeReferenceTree = {
    //         uid: node.uid,
    //         name: node.label,
    //         typeName: node.typeName,
    //     }



    //     if (node.parent) {
    //         await Ajax.Node.Get(node.parent.uid).then((response) => {
    //             tree.parent = {
    //                 uid: response.data.uid,
    //                 name: response.data.label,
    //                 typeName: response.data.typeName,
    //             }
    //         })
    //     }

    //     return tree
    // }


    private generateErrorNode = (uid: string, response: AxiosResponse) => {

        var label = "Server error"
        if (response?.status === 404) {
            label = "Not found"
        } else if (response?.status === 403) {
            label = "Unauthorised"
        }

        var node = new ErrorNode({
            uid: uid,
            typeName: "Error",
            label: label,
            detail: response?.data?.detail
        })

        return node
    }


    private updateBreadcrumb = async (node) => {

        var existingReference = this.breadcrumb.find((nr, i) => nr.uid === node.uid)
        var existingReferenceToParent = this.breadcrumb.find((nr, i) => nr.uid === node.parent?.uid)

        if (node.uid.includes("ACCOUNT")) { // Account nodes should always be the first item in the breadcrumb
            
            console.log("Setting breadcrumb")
            this.breadcrumb = [{uid: node.uid, name: node.label}]

        } else if (existingReference) { // Trunc back to existing reference
            
            var existingReferenceIndex = this.breadcrumb.indexOf(existingReference)
            this.breadcrumb = this.breadcrumb.slice(0, existingReferenceIndex + 1)
        
        } else if (existingReferenceToParent) { // Trunc back to existing reference to parent and append new node
            
            var existingReferenceToParentIndex = this.breadcrumb.indexOf(existingReferenceToParent)
            this.breadcrumb = [
                ...this.breadcrumb.slice(0, existingReferenceToParentIndex + 1),
                {uid: node.uid, name: node.label}
            ]
        
        } else { // Last resort, get fresh path
    
            Ajax.Node.GetPath(node.uid).then((response) => {
                this.breadcrumb = response.data.nodeRefs
            }).catch((response) => console.log("Couldn't update breadcrumb", response))

        }
    }


    getDisplayName = (node: any) => {

        switch (node?.typeName) {
            case "Error":
            case "Aora.Platform.Data.UserNode":
            case "Aora.Platform.Data.AccountNode":
            case "Aora.Nationality.Data.SponsorAccountNode":
            case "Aora.Nationality.Data.SponsorNode":
            case "Aora.Nationality.Data.PersonNode":
                return node.label
            case "Aora.Platform.Data.FolderNode":
            case "Aora.Platform.Data.CaseNode":
                return node.name
            case "Aora.Platform.Data.EnquiryNode":
                var id = node.uid.split("_").length === 2 ? node.uid.split("_")[1] : "???"
                return "Enquiry " + id
            case undefined:
                return "Node"
            default:
                return node.uid
        }
    }

    getDisplayType = (node: any, plural: boolean = false) => {

        switch (node?.typeName) {
            case "Aora.Platform.Data.AccountNode":
                if (node.uid === "ACCOUNT_0") return "System admin account"
                if (node.uid === store.AppStore.sessionInfo.userAccount.uid) return "Legal adviser"
                var typeName = "Private Client"
                return plural ? typeName + "s" : typeName

            case "Aora.Nationality.Data.SponsorAccountNode":
                return plural ? "Sponsors" : "Sponsor"

            case "Aora.Nationality.Data.SponsorNode":
                return plural ? "Sponsor details" : "Sponsor details"

            case "Aora.Platform.Data.UserNode":
                return plural ? "Users" : "User"

            case "Aora.Platform.Data.FolderNode":
                return plural ? "Folders" : "Folder"

            case "Aora.Platform.Data.CaseNode":
                return plural ? "Cases" : "Case"

            case "Aora.Nationality.Data.PersonNode":
                return plural ? "People" : "Person"

            case "Aora.Platform.Data.EnquiryNode":
                return plural ? "Enquiries" : "Enquiry"

            case "Aora.Platform.Data.ReportNode":
                return plural ? "Reports" : "Report"

            case "Aora.Platform.Data.AutomationNode":
                return plural ? "Automations" : "Automation"

            default:
                return plural ? "Entities" : "Entity"

        }
    }

    scrollToDetailNode = (nodeUid: string) => {

        var detailPanel = document.getElementById("panel-2")
        var scrollEl = detailPanel.getElementsByClassName("panel-body")

        var elements = document.querySelectorAll(`[data-uid='${nodeUid}']`)

        if (elements.length && scrollEl.length) {
            var attrElement = elements[0].getBoundingClientRect();
            scrollEl[0].scrollTo(0, attrElement.top)
            console.log(attrElement.top)
        }
    }

}