import { format } from "date-fns";
import { observer } from "mobx-react-lite";
import { Button, CircularProgress } from "@mui/material";
import EnquiryStatusSection from "./EnquiryStatusSection";
import { Alert, AlertModal, CloseModal } from "../../../../..";
import Ajax from "../../../../../Ajax";
import { store } from "../../../../../stores/Store";
import EnquiryOutcomeDetails from "./EnquiryOutcomeDetails";


export default function EnquiryOutcomeModal ({enquiry}) {

    const {getSubject} = store.EnquiryStore

    // if (loadingLeafNode || !enquiry) {
    //     return (
    //         <div style={{display: "flex", alignItems: "center"}}>
    //             <CircularProgress style={{width: "16px", height: "16px", margin: "10px"}} />
    //             <h4>Generating outcome...</h4>
    //         </div>
    //     )
    // }

    switch (enquiry.status) {

        case "FINAL":
        case "INTERIM":

            return (
                <div className="form">
                    <h2>Interim result</h2>
                    <p>We have reached an outcome for {getSubject(enquiry)?.name} under the law of the {enquiry.ruleSystem}.</p>
                    <p>There is still some information needed to complete the full enquiry and generate reports, choose to continue below.</p>

                    <EnquiryStatusSection enquiry={enquiry} editable={false} showCertainty={false} maxHeight="400px" />

                    <div className="form-buttons">
                        <Button className="btn btn-sm" onClick={() => CloseModal()}>Close</Button>
                        <Button
                            color="success"
                            className="btn btn-sm"
                            variant="contained"
                            onClick={() => CloseModal().then(() => Ajax.Enquiry.Run(enquiry.uid))}>
                            Complete now
                        </Button>
                    </div>
                </div>
            )

        case "REPORT_DATA_REQUIRED":
        case "DEFINITIVE":

            return (
                <div className="form">
                    <h2>Full outcome reached</h2>

                    <p>Outcome of determination for {getSubject(enquiry)?.name} under the law of the {enquiry.ruleSystem} on {format(new Date(enquiry.interestDate), "dd LLLL yyyy")}</p>

                    <EnquiryStatusSection enquiry={enquiry} editable={false} maxHeight="400px" />

                    <div className="form-buttons">
                        <Button className="btn btn-sm" onClick={() => CloseModal()}>Close</Button>
                        
                        {/* <Button
                            color="success"
                            className="btn btn-sm"
                            variant="contained"
                            onClick={async () => {
                                store.AlertStore.Close()
                                var alert = Alert({message: "Getting report options."})
                                store.ReportStore.openReportRequestForm().finally(() => alert.dismiss())
                            }}>
                            Next
                        </Button> */}

                        <Button
                            color="success"
                            className="btn btn-sm"
                            variant="contained"
                            onClick={async () => {
                                store.AlertStore.Close()
                                var alert = Alert({message: "Generating reports..."})
                                store.ReportStore.orderAllReports(enquiry.uid).catch(() => {
                                    alert.dismiss()
                                }).then(() => {
                                    alert.dismiss()
                                    setTimeout(() => {
                                        if (!store.AppStore.sessionState) {
                                            AlertModal({title: "Outcome Details", width: "850px", body: (
                                                <EnquiryOutcomeDetails enquiry={enquiry} />
                                            )})
                                        }
                                    }, 1000)
                                })
                            }}>
                            View outcome details
                        </Button>
                    </div>
                </div>
            )

        case "RERUN_REQUIRED":
            return (
                <div className="form">
                    <h2>A re-run is required</h2>
                    <p>
                        An outcome of determination for {getSubject(enquiry)?.name} under the law of the {enquiry.ruleSystem} had previously been reached,
                        however, subsequent changes in the relevant data have made the result invalid and requires you to re-run the enquiry.
                    </p>

                    <EnquiryStatusSection enquiry={enquiry} editable={false} maxHeight="400px" />

                    <div className="form-buttons">
                        <Button className="btn btn-sm" onClick={() => CloseModal()}>Close</Button>
                        <Button
                            color="success"
                            className="btn btn-sm"
                            variant="contained"
                            onClick={() => CloseModal().then(() => Ajax.Enquiry.Run(enquiry.uid))}>
                            Re-run
                        </Button>
                    </div>
                </div>
            )

        default:
            return (
                <div className="form">
                    <h2>No outcome reached</h2>
                    <p>Continue running the enquiry in order to reach an outcome</p>
                </div>
            )
    }

}