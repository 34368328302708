import { makeAutoObservable } from 'mobx'
import Ajax from '../Ajax'
import { Alert, AlertModal, DisplayValue } from '..'
import QuestionLog from '../components/views/main/QuestionLog'
import { DataSource, Template } from '../models/Interfaces';
import { isValid } from 'date-fns';


export class QuestionnaireStore {
    
    dataSources: DataSource[] = null;
    dataSourceUsage: any[] = [];
    loading: boolean = true;
    templates: Template[] | null = null;
    questionnaire: any = null;
    questionnaires: any[] | null = null;
    currentQuestionnaireId: string | null = null;
    alert: any = null;

    order: "asc" | "desc" = "desc"
    orderedBy: string = "updatedAt"
    searchString: string = ""
    searchAdvisorUid: string = ""

    constructor() {
        makeAutoObservable(this)
    }

    loadQuestionnaire = async (caseUid, questionnaireUidArray) => {
        await Ajax.Enquiry.LoadQuestionnaires(caseUid, questionnaireUidArray).catch((response) => {  
            Alert({message: response.data?.detail})
        })
    }

    handleDataSourceChangeNotification = (docn) => {
        console.log("DataSourceChangeNotification", docn)
        this.alert?.dismiss()
        
        if (docn.newSources) {
            console.log("Registering loaded questionnaire")
            this.dataSources = docn.newSources
            this.dataSourceUsage = []
        } else {
            console.log("Clearing loaded questionnaire")
            this.dataSources = null
            this.dataSourceUsage = []
        }
    }

    handleInformationRequestIntercepted = (iri: any) => {

        console.log("InformationRequestIntercepted", iri)
        this.dataSourceUsage.push(iri)

        if (this.alert) {
            this.alert.dismiss()
        }

        this.alert = Alert({
            persist: true,
            color: "success", 
            // horizontalAnchor: "center", 
            message: `${this.dataSourceUsage.length} answer${this.dataSourceUsage.length === 1 ? "" : "s"} sourced`, 
            actions: [{ label: "See answers", color: "info", action: () => AlertModal({id: "answer-feed", body: <QuestionLog />, width: "450px"}) }]
        })
    }

    getQuestionnaires = async () => {
        this.loading = true

        await Ajax.Questionnaire.All().then((response) => {
            this.questionnaires = response.data
        }).catch(() => {
            this.questionnaires = []
        })

        this.loading = false
    }

    getTemplates = async () => {
        await Ajax.QuestionnaireTemplate.All().then((response) => {
            this.templates = response.data
        }).catch(() => {
            this.templates = []
        })

        this.loading = false
    }

    // #region Filtering

    setSeachAdvisorUid = (advisorUid) => {
        this.searchAdvisorUid = advisorUid
    }
    
    setOrder = (order) => {
        this.order = order
    }

    setOrderedBy = (orderedBy) => {
        this.orderedBy = orderedBy
    }

    get orderPhrase () {
        var orderPhrase = this.order === "asc" ? ", reverse" : ""

        if (["On", "At"].includes(this.orderedBy.slice(-2))) {
            orderPhrase = this.order === "asc" ? ", oldest first" : ", newest first"
        }

        return `Ordered by '${DisplayValue("dict_key", this.orderedBy)}'${orderPhrase}`
    }

    setSearchString = (str) => {
        this.searchString = str
    }

    filterQuestionnaires = (questionnaires) => {
        
        if (!Array.isArray(questionnaires) || !questionnaires.length){
            return []
        }

        // Filtering
        if (this.searchAdvisorUid) {
            questionnaires = questionnaires.filter(q => q.respondent.advisorUid === this.searchAdvisorUid)
        }

        if (this.searchString) {
            questionnaires = questionnaires.filter(q => {
                return (
                    q.uid === this.searchString ||
                    q.displayName?.toLowerCase().includes(this.searchString.toLowerCase()) ||
                    q.template?.displayName?.toLowerCase().includes(this.searchString.toLowerCase()) ||
                    q.respondent?.respondentEmail?.toLowerCase().includes(this.searchString.toLowerCase()) ||
                    q.notificationEmail?.toLowerCase().includes(this.searchString.toLowerCase())
                )
            })
        }

        // Ordering
        questionnaires = [...questionnaires].sort((a, b) => {

            var aValue = null
            var bValue = null

            if (this.orderedBy === "template") {
                aValue = a.template?.displayName
                bValue = b.template?.displayName
            } else if (this.orderedBy === "respondent") {
                aValue = a.respondent.respondentEmail
                bValue = b.respondent.respondentEmail
            } else if (Object.keys(a).includes(this.orderedBy) && Object.keys(b).includes(this.orderedBy)) {
                aValue = a[this.orderedBy]
                bValue = b[this.orderedBy]
            }

            // Date fields need opposite ordering
            if (["On", "At"].includes(this.orderedBy.slice(-2))) {
                return this.order === "desc" ? bValue?.localeCompare(aValue) : aValue?.localeCompare(bValue)
            }
            
            return this.order === "desc" ? aValue?.localeCompare(bValue) : bValue?.localeCompare(aValue)
        })

        return questionnaires
    }

    // #endregion

    isHidden = (fieldOrPage, answers) => {

        var valuesToLookFor = {...fieldOrPage.dependsOn, ...fieldOrPage.dependsOnAny}

        var fieldsKeysSatisfied = Object.keys(valuesToLookFor).filter((key) => {
            var valueToLookFor = valuesToLookFor[key]
            return Array.isArray(valueToLookFor) ? valueToLookFor.includes(answers[key]) : valueToLookFor === answers[key]
        })

        if (fieldOrPage.dependsOn) {
            return Object.keys(valuesToLookFor).length !== fieldsKeysSatisfied.length
        }

        if (fieldOrPage.dependsOnAny) {
            return fieldsKeysSatisfied?.length === 0
        }

        return false
    }

    getFieldValue = (field, answers) => {
        var value = answers[field.name]

        if (field.options?.hasOwnProperty(value)) {
            value = field.options[value]
        }

        if (field.type === "checkbox") {
            value = value === "true" ? "Yes" : "No"
        }
        
        return value
    }

    formatPlaceholders = (message, answers) => {
        try {
            if (message) {
                var placeholders = message.matchAll("(?<={).+?(?=})", message)
                
                placeholders.forEach((placeholder) => {
                    var value = answers[placeholder[0]]
                    if (value) {
                        message = message.replace(`{${placeholder[0]}}`, value)
                    }
                })
            }
        } catch {
            console.log("Error when converting questionnaire placeholders")
        }
        return message
    }

    getPageList = (pages, answers, returnSubmittedPages = true) => {
        console.log("Getting page list")
        var pageList = []

        const handlePages = (pages) => {
            pages.forEach(page => {
                
                var hide = this.isHidden(page, answers)

                if (!hide) {
                    pageList.push(page)
                }

                // Check for conditional pages
                page.fields?.forEach(field => {
                    var value = answers[field.name]
                    if (field.conditional_pages) {
                        if (value in field.conditional_pages) {
                            handlePages(field.conditional_pages[value])
                        } else if ("else" in field.conditional_pages) {
                            handlePages(field.conditional_pages["else"])
                        }
                    }
                })
            })
        }

        handlePages(pages)

        if (!answers.submitted_pages) {
            return returnSubmittedPages ? [] : pageList
        }

        if (returnSubmittedPages) {
            return pageList.filter(page => answers.submitted_pages.includes(page.id))
        }
        
        return pageList.filter(page => !answers.submitted_pages.includes(page.id))
    }

    getQuestionnaireList = async (questionnaireUidArray: string[]) => {
        var qArray = []
    
        // Get questionnaires
        for (var i = 0; i < questionnaireUidArray.length; i += 1) {
            await Ajax.Questionnaire.Get(questionnaireUidArray[i]).then(response => {
                response.data.answers = JSON.parse(response.data.answers)
                qArray.push(response.data)
            })
        }
    
        return qArray
    }


    assignPageIDs = async (templateJson: string): Promise<string> => {

        console.log(templateJson)

        var templateBody: ITemplateBody = JSON.parse(templateJson)
    
        if (!templateBody.pages || !Array.isArray(templateBody.pages)) return templateJson
    
        const traverse = async (pages: IPage[]) => {
            
            for (var p=0; p < pages.length; p+=1) {
            
                var page: IPage = pages[p]
                page.id = await this.generatePageId(page)
                
                if (page.fields && Array.isArray(page.fields)) {
                    for (var f=0; f < page.fields.length; f+=1) {
                        
                        var field: IField = page.fields[f]
                        
                        if (field.conditional_pages) {
                            Object.values(field.conditional_pages).forEach((pageList: IPage[]) => {
                                traverse(pageList)
                            })
                        }
                    }
                }
            }
        }
    
        await traverse(templateBody.pages)
    
        return JSON.stringify(templateBody, null, "    ")
    }
    
    
    generatePageId = async (page: IPage) => {
    
        // Remove ID and conditional_pages
        var pageToHash = {
            ...page,
            id: null,
            fields: page.fields?.map(field => {
                return {...field, conditional_pages: null}
            })
        }
    
        const encoder = new TextEncoder();
        const data = encoder.encode(JSON.stringify(pageToHash));
        const hashBuffer = await crypto.subtle.digest("SHA-256", data)
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
        return hashHex
    }
}


// Template components
export interface ITemplateBody {
    name: string;
    pages: IPage[];
}

export interface IPage {
    id: string;
    title: string;
    description?: string;
    table?: ITable;
    fields?: IField[];
    submitted?: boolean; 
    dependsOn?: Record<string, string>;
    dependsOnAny?: Record<string, string[]>;
    conditional_skip?: string;
}

export interface IField {
    name: string;
    type: string;
    label?: string;
    options?: Record<string, string>;
    guess?: string;
    regex?: string;
    regexMessage?: string;
    min?: number;
    max?: number;
    evidence_types?: string[];
    allowed_formats?: string[];
    helpText?: string;
    required?: boolean;
    setListKey?: string;
    dependsOn?: Record<string, string>;
    dependsOnAny?: Record<string, string[]>;
    conditional_pages?: Record<string, IPage[]> ;
}

export interface ITable {
    fields: IField[];
    list_name?: string;
    list_item_label?: string;
    min_rows?: number;
    max_rows?: number;
    columns?: Record<string, string>;
}