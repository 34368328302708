import React from "react";
import TruncText from "../../../../../misc/TruncText";
import { DisplayDictionary, DisplayValue } from "../../../../..";
import { Button } from "@mui/material";
import Field from "../../../../form/fields/Field";
import { attributeKeys, formatKey } from "../../../../../misc/AttributeKeys";
import { Padding } from "@mui/icons-material";


export default function AutomationAnwers ({automator}) {

    const valueStatuses = Object.keys(DisplayDictionary("automation_value_validity"))
    const [valueStatusFilter, setValueStatusFilter] = React.useState(valueStatuses)

    var downloads = automator.downloads?.map(d => JSON.parse(d))

    // Don't show page if there are no fields to show after filtering is applied
    var submitted_pages = automator?.submitted_pages.filter((page, i) => {

        var fields = page.fields.filter(f =>  (valueStatusFilter.includes(f.value_status) && f.name) || f.type === "download")

        return !!fields.length
    })

    const printContainer = React.useRef(null)

    const print = () => {
        var WinPrint = window.open('#', '_blank', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');

        if (printContainer?.current?.innerHTML) {
            var htmlHead = window.document.head.innerHTML
            htmlHead = htmlHead.replace("<script", "<!--<script")
            htmlHead = htmlHead.replace("</script>", "</script>-->")
            htmlHead = htmlHead.replace("<title", "<!--<title")
            htmlHead = htmlHead.replace("</title>", "</title>-->")

            WinPrint.document.write(`
                <html>
                    <head>
                        ${htmlHead}
                        <title>Automation Review</title>
                    </head>
                    <body>
                        <div class="automation-answers">
                            ${printContainer.current.innerHTML}
                        </div>
                    </body>
                </html>
            `)
            WinPrint.document.close()
            WinPrint.focus()
            setTimeout(() => {
                WinPrint.print()
            }, 1000)
        }
    }

    return (
        <div className="automation-answers">
            <div ref={printContainer} style={{maxHeight: "70vh", overflowY: "scroll", overflowX: "scroll"}}>
                <div className="answers-field answers-column-headers" style={{overflow: "hidden"}}>
                    <span>Field name</span>
                    <span>Entered value</span>
                    <div style={{maxWidth: "200px"}}>
                        <Field.MultipleSelect
                            checkboxes
                            name="field-filter"
                            // label="Filter fields by validity of answer"
                            value={valueStatusFilter}
                            warning={false}
                            options={DisplayDictionary("automation_value_validity", true)}
                            onChange={(e) => {
                                setValueStatusFilter(e.value)
                            }} />
                    </div>
                </div>
                {submitted_pages.map((page, i) => {
                    var fieldsToShow = page.fields.filter(f => valueStatusFilter.includes(f.value_status) && f.name)
                    var downloadFields = page.fields.filter(f => f.type === "download")

                    return (
                        <div key={i} className="answers-section">
                            <h4>{page.title}</h4>

                            {fieldsToShow.map((field, i) => <FieldReadOnly key={i} field={field} /> )}
                            {downloadFields.map((field, i) => <FieldDownload key={i} field={field} download={downloads.find(d => field.value === d?.field_name)} /> )}
                        </div>
                    )    
                })}
            </div>

            {/* <small className="link">Print answers as PDF</small> */}
            <Button style={{margin: "4px 0 0 auto"}} className="btn btn-xs" variant="contained" color="success" onClick={() => print()}>Print answers as PDF</Button>
        </div>
    )
}



function FieldReadOnly ({field}) {

    if (!field.name) return <></>

	var fieldStatus = ""
	
    var label = field.label ? field.label : formatKey(field.name)

    if (["true", "false"].includes(field.value)) {
        field.value = field.value.replace("true", "Yes").replace("false", "No")
    }

	if (field.value_status) {
		switch (field.value_status) {
            case "GUESS":
                fieldStatus = "guessed-value" // Amber
                break
            case "EMPTY":
			case "FALSE":
				fieldStatus = "false-value" // Red
				break
			case "GENUINE":
				fieldStatus = "genuine-value"
				break
			default:
				fieldStatus = ""
				break
		}
	}

	return (
		<div className="answers-field">
			<span>{label}</span>
			<span><TruncText style={{color: "grey", fontWeight: "300"}} text={field.value} /></span>
			<span className={fieldStatus}>{DisplayValue("automation_value_validity", field.value_status)}</span>
		</div>
	)
}

function FieldDownload ({field, download}) {

    if (!field) return <></>
    
    var label = field?.label ? field.label : formatKey(field.value)

    return (
		<div className="answers-field">
			<span>{label}</span>
			<span><TruncText style={{color: "grey", fontWeight: "300"}} text={download?.display_name} /></span>
			<span>
                {download?.binary ? (
                    <Button 
                        onClick={() => downloadBase64File(download.binary, download.format, download.display_name)}
                        className="btn btn-sm" 
                        variant="contained" 
                        color="success">
                        Download
                    </Button>
                ) : (
                    <>Download failed</>
                )}
            </span>
		</div>
	)
}


function downloadBase64File(base64String, mimeType, fileName) {
    
    const link = document.createElement('a');
    
    link.href = `data:${mimeType};base64,${base64String}`
    link.download = fileName;
    
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}