import React from "react";
import { TextField as MuiTextField } from "@mui/material";
import { InputFieldProps } from "../../../models/Interfaces";


interface TextFieldProps extends InputFieldProps {
    type?: "text" | "number" | "password";
    rows?: number;
    spellCheck?: boolean;
    decimalPlaces?: number;
    characterCount?: boolean;
}

export default function TextField ({

    id              = "",
    name,
    value           = undefined,
    label           = "",
    type            = "text",
    helpText        = "",
    warning         = "",
    placeholder     = "",
    onChange,
    formik          = false,
    disabled        = false,
    autoFocus       = false,
    autoComplete    = false,
    spellCheck      = false,
    rows            = 1,
    decimalPlaces   = 0,
    characterCount  = false,
    style           = {},
    variant         = "standard"

}: TextFieldProps) {

    const [internalId] = React.useState(id ? id : Math.random().toString())

    return (
        <div className="form-field" style={style}>
            <MuiTextField
                id={internalId}
                name={name}
                value={value !== null ? value : ""}
                label={label}
                type={type}
                rows={rows}
                variant={variant ? variant : "standard"}
                error={!!warning}
                spellCheck={spellCheck}
                multiline={rows > 1}
                autoFocus={autoFocus}
                disabled={disabled}
                placeholder={placeholder}
                slotProps={{
                    htmlInput: {
                        "aria-describedby": `${internalId}-warning ${internalId}-help`,
                        "aria-invalid": !!warning,
                        step: decimalPlaces === 0 ? "1" : "0.01",
                        type: type,
                        autoComplete: autoComplete ? "on" : "off",
                        autoCorrect: "off",
                        autoCapitalize: "none"
                    }
                }}
                onChange={(e) => {
                    
                    // Round decimal places
                    if (type === "number") {
                        var stringValue = e.target.value.toString()
                        
                        if (stringValue.includes(".") && stringValue.split(".")[1].length > decimalPlaces) {
                            e.target.value = parseFloat(e.target.value).toFixed(decimalPlaces)
                        }
                    }

                    formik ? onChange(e) : onChange({name: name, value: e.target.value})
                }}
            />

            {characterCount ? (
                <small>{value?.length} Characters</small>
            ) : null}

            {warning !== false && typeof warning === "string" ? (
                <span className="warning">{warning}</span>
            ) : null}

            {helpText ? (
                <span className="help-text"id={`${internalId}-help`}>{helpText}</span>
            ) : null}
        </div>
    )
}