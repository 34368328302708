import { observer } from "mobx-react-lite"
import { store } from "../../../../../stores/Store"
import Panel from "../../../../layout/Panel"
import PanelBody from "../../../../layout/PanelBody"
import PanelHeader from "../../../../layout/PanelHeader"
import PanelLabel from "../../../../layout/PanelLabel"
import { DetailPanelProps } from "../DetailPanel"
import AutomationSection from "../automation/AutomationSection"
import ReportSection from "../report/ReportSection"
import EnquiryDataSourcesSection from "./EnquiryDataSourcesSection"
import EnquiryDetailSection from "./EnquiryDetailSection"
import EnquiryStatusIndicator from "./EnquiryStatusIndicator"
import NextStepsSection from "./NextStepsSection"


export default observer(function EnquiryDetail ({index}: DetailPanelProps) {

    const {enquiry, getSubject} = store.EnquiryStore

    return (
        <Panel index={index} id="DETAIL" className="detail" width="none" loadingContent={!enquiry}>

            <PanelLabel title="Enquiry details" />

            <PanelHeader 
                closeable 
                node={enquiry} 
                title={`Enquiry for ${getSubject(enquiry).name}`} 
                onClose={() => store.NodeStore.setLeafNode(null)}>
                {/* <EnquiryCta /> */}
                <EnquiryStatusIndicator enquiry={enquiry} />
            </PanelHeader>

            <PanelBody>
                <div className="panel-columns enquiry-detail">
                    <div>
                        {/* <EnquiryStatusSection enquiry={enquiry} /> */}
                        <NextStepsSection />
                        <EnquiryDetailSection enquiry={enquiry} />
                        <EnquiryDataSourcesSection />
                        {/* <EnquirySettingsSection /> */}
                    </div>

                    <div>
                        <ReportSection />
                        <AutomationSection />
                    </div>
                </div>
            </PanelBody>

        </Panel>
    )
})