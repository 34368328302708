import { observer } from "mobx-react-lite";
import { store } from "../../../../../stores/Store";
import { Button } from "@mui/material";
import { Alert, AlertModal } from "../../../../..";
import EnquiryResumeForm from "../../../../form/enquiry/EnquiryResumeForm";
import EnquiryOutcomeDetails from "./EnquiryOutcomeDetails";


export default observer(function EnquiryCta () {

    const { enquiry } = store.EnquiryStore

    if (!enquiry) return <></>

    const statusesSection = enquiry.childrenObjects?.find(c => c.name === "STATUSES")
    const positiveStatus = statusesSection?.childrenObjects?.find(s => s.selected && s.value === "Y")
    const validReports = !!store.ReportStore.enquiryReports?.find(r => r.valid)
    const enquiryRanPriorToLastUpdate = new Date(enquiry.lastDefinitiveOutcomeDt) < new Date(store.AppStore.sessionInfo.versionDate)

    if (enquiry.status === "INTERIM") {
        return (
            <div className="section-subheader" style={{border: "none"}}>
                <small>This enquiry has reached an outcome based on the information you provided and also some assumptions made by AORA. For a full outcome you must confirm these assumptions.</small>
                <Button
                    color="success"
                    className="btn btn-md"
                    variant="contained"
                    disabled={!!store.AppStore.sessionState}
                    style={{margin: "8px 0"}}
                    onClick={() => AlertModal({body: <EnquiryResumeForm />, title: "Confirm assumptions", width: "700px"})}>
                    Confirm assumptions
                </Button>
            </div>
        )
    }

    if (enquiry.status === "REPORT_DATA_REQUIRED" || enquiry.status === "FINAL") {
        return (
            <div className="section-subheader" style={{border: "none"}}>
                <small>This enquiry has reached a full outcome, however, the reports which you requested require some more information.</small>
                <Button
                    color="success"
                    className="btn btn-md"
                    variant="contained"
                    disabled={!!store.AppStore.sessionState}
                    style={{margin: "8px 0"}}
                    onClick={() => AlertModal({body: <EnquiryResumeForm />, title: "Complete now", width: "700px"})}>
                    Complete now
                </Button>
            </div>
        )
    }

    if (enquiry.status === "RERUN_REQUIRED") {
        return (
            <div className="section-subheader" style={{border: "none"}}>
                <small>This enquiry has reached an outcome, however, the data has since been changed and the enquiry must be rerun.</small>
                <Button
                    color="success"
                    className="btn btn-md"
                    variant="contained"
                    disabled={!!store.AppStore.sessionState}
                    style={{margin: "8px 0"}}
                    onClick={() => AlertModal({body: <EnquiryResumeForm />, title: "Rerun enquiry", width: "700px"})}>
                    Rerun enquiry
                </Button>
            </div>
        )
    }

    if (enquiry.status === "INITIAL") {
        return (
            <div className="section-subheader" style={{border: "none"}}>
                <small>This enquiry has not yet reached an outcome.</small>
                <Button 
                    color="success"
                    className="btn btn-md"
                    variant="contained"
                    disabled={!!store.AppStore.sessionState}
                    style={{margin: "8px 0"}}
                    onClick={() => AlertModal({body: <EnquiryResumeForm />, title: "Resume enquiry", width: "700px"})}>
                    Resume enquiry
                </Button>
            </div>
        )
    }

    if (enquiry.status === "DEFINITIVE" && positiveStatus) {
        return (
            <div className="section-subheader" style={{border: "none"}}>
                <small>The enquiry has reached a positive outcome.</small>

                {!validReports ? (
                    // <Button
                    //     color="success"
                    //     variant="contained"
                    //     className="btn btn-md"
                    //     disabled={!!store.AppStore.sessionState}
                    //     style={{margin: "8px 0"}}
                    //     onClick={() => store.ReportStore.orderAllReports(enquiry.uid)}>
                    //     Complete Enquiry
                    // </Button>
                    <Button
                        color="success"
                        variant="contained"
                        className="btn btn-md"
                        style={{margin: "8px 0"}}
                        disabled={!!store.AppStore.sessionState}
                        onClick={async () => {
                            store.AlertStore.Close()
                            var alert = Alert({message: "Generating reports..."})
                            store.ReportStore.orderAllReports(enquiry.uid).catch(() => {
                                alert.dismiss()
                            }).then(() => {
                                alert.dismiss()
                                setTimeout(() => {
                                    if (!!store.AppStore.sessionState) {
                                        AlertModal({title: "Outcome Details", width: "850px", body: (
                                            <EnquiryOutcomeDetails enquiry={enquiry} />
                                        )})
                                    }
                                }, 1000)
                            })
                        }}>
                        View outcome details
                    </Button>
                ) : null}

                {validReports && enquiry.additionalOutcomeData?.events?.length ? (
                    <Button
                        color="success"
                        variant="contained"
                        className="btn btn-md"
                        style={{margin: "8px 0"}}
                        disabled={!!store.AppStore.sessionState}
                        onClick={() => AlertModal({title: "Outcome Details", width: "850px", body: (
                            <EnquiryOutcomeDetails enquiry={enquiry} />
                        )})}>
                        View Outcome Details
                    </Button>
                ) : null}

                {validReports && enquiryRanPriorToLastUpdate ? (
                    <>
                        <small>The server has been updated since this enquiry was last run.</small>
                        <Button
                            color="success"
                            variant="contained"
                            className="btn btn-md"
                            style={{margin: "8px 0"}}
                            disabled={!!store.AppStore.sessionState}
                            onClick={() => store.EnquiryStore.rerun()}>
                            Rerun Enquiry
                        </Button>
                    </>
                ) : null}
            </div>
        )
    }

    if (enquiry.status === "DEFINITIVE" && !positiveStatus) {

        return (
            <div className="section-subheader" style={{border: "none"}}>
                <small>The outcome of the enquiry was negative.</small>

                {!validReports ? (
                    <Button
                        color="success"
                        variant="contained"
                        className="btn btn-md"
                        style={{margin: "8px 0"}}
                        disabled={!!store.AppStore.sessionState}
                        onClick={() => store.ReportStore.orderAllReports(enquiry.uid)}>
                        Complete Enquiry
                    </Button>
                ) : null}

                {validReports && enquiryRanPriorToLastUpdate ? (
                    <>
                        <small>The server has been updated since this enquiry was last run.</small>
                        <Button
                            color="success"
                            variant="contained"
                            className="btn btn-md"
                            style={{margin: "8px 0"}}
                            disabled={!!store.AppStore.sessionState}
                            onClick={() => store.EnquiryStore.rerun()}>
                            Rerun Enquiry
                        </Button>
                    </>
                ) : null}
            </div>
        )
    }

    return <></>
})