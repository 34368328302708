import React from "react"
import { Button } from "@mui/material"
import { Link } from "react-router-dom"
import { observer } from "mobx-react-lite"
import { Icons } from "../../../misc/Icons"
import { store } from "../../../stores/Store"
import InlineMenu from "../../../misc/InlineMenu"


export default observer(function BreadCrumb () {

    const { accounts, homeNodeUid, setHomeNodeUid, breadcrumb, navigateNode} = store.NodeStore
    
    const homeAccount = accounts?.find(a => a.uid === homeNodeUid)
    const homeAccountIsChanged = homeAccount?.uid !== store.AppStore.sessionInfo.userAccount.uid

    const revertHomeAccount = () => {
        setHomeNodeUid(store.AppStore.sessionInfo.userAccount.uid)
        navigateNode(null)
    }

    return (
        <div className={`breadcrumbs ${store.AppStore.sessionState ? "disabled" : ""}`}>
            {homeAccount && homeAccountIsChanged ? (
                <div className="btn-group">
                    <Button
                        to="/app/main"
                        component={Link}
                        color="secondary" 
                        variant="contained"
                        className="btn btn-sm" >
                        <Icons.Home style={{color: "white"}} />
                        Home ({homeAccount.label})
                    </Button>

                    <InlineMenu opener={(
                        <Button
                            color="secondary" 
                            variant="contained"
                            className="btn btn-sm">
                            <Icons.More style={{color: "white"}} />
                        </Button>
                    )} menuItems={[
                        {label: "Back to your Home folder", action: () => revertHomeAccount()}
                    ]} />
                </div>
            ) : (
                <Button
                    to="/app/main"
                    component={Link}
                    color="success" 
                    variant="contained"
                    className="btn btn-sm">
                    <Icons.Home style={{color: "white"}} />
                    Home 
                </Button>
            )}

            {breadcrumb?.map((nodeRef, i) => (
                <React.Fragment key={i}>
                    <div className="breadcrumb-delimiter">{i > 0 ? <>/</> : null}</div>

                    <Link className="breadcrumb-link link" to={`/app/main/${nodeRef?.uid}`} aria-label={`Back to ${nodeRef?.name ? nodeRef?.name : nodeRef?.uid}`}>
                        {nodeRef?.name ? nodeRef?.name : nodeRef?.uid}
                    </Link>
                </React.Fragment>
            ))}
        </div>
    )
})