import React from "react"
import * as Yup from 'yup'
import Ajax from "../../../Ajax"
import { useFormik } from "formik"
import Field from "../fields/Field"
import { Button } from "@mui/material"
import { DisplayDictionary } from "../../.."
import { store } from "../../../stores/Store"
import { Icons } from "../../../misc/Icons"

// I separated the form into two components so that I could insert this form to be a section of another form.

export function UserCreateForm ({account, onSuccess = () => {}}) {
    return (
        <div style={{display: "grid", gap: "20px"}}>
            <h2>Create user</h2>

            <UserFormOnly
                allowWithoutCredentials={false}
                handleCancel={() => store.AlertStore.Close()}
                handleSubmit={async (values) => {
                    await Ajax.Node.Create("user", account.uid, values).then((response) => {
                        onSuccess()
                    })
                }} />
        </div>
    )
}

export function UserUpdateForm ({user, onSuccess = () => {}, requestFullUser = false}) {

    const [fullUser, setFullUser] = React.useState(null)

    React.useEffect(() => {
        if (requestFullUser) {
            Ajax.Node.Get(user.uid).then((response) => {
                setFullUser(response.data)
            })
        } else {
            setFullUser(user)
        }
    }, [])

    if (fullUser === null) return <p>Loading form...</p>

    return (
        <div style={{display: "grid", gap: "20px"}}>
            <h2>Update user</h2>

            <UserFormOnly
                initialValues={fullUser}
                handleCancel={() => store.AlertStore.Close()}
                handleSubmit={async (values) => {
                    await Ajax.Node.Edit("user", values).then((response) => {
                        onSuccess()
                    })
                }} />
        </div>
    )
}

export default function UserFormOnly ({
    handleSubmit,
    handleCancel,
    allowWithoutCredentials = true,
    guidance = null,
    initialValues = null,
    hidden = false,
    onSuccess = () => {}
}) {

    const editMode = !!initialValues?.uid
    const existingCredentials = !!initialValues?.username

    const [error, setError] = React.useState<string>("")
    const [submitting, setSubmitting] = React.useState(false)
    const [revealPasswordText, setRevealPasswordText] = React.useState(false)

    var defaultInitialValues = {
        firstName: "",
        familyName: "",
        sex: "M",
        title: "",
        email: "",
        phone: "",
        username: null,
        password: null,
        isAccountManager: false,
        active: !allowWithoutCredentials
    }

    // Allow some or all default values to be overridden
    if (initialValues) {
        initialValues = {...defaultInitialValues, ...initialValues}
    } else {
        initialValues = defaultInitialValues
    }

    var formik = useFormik({

        initialValues: initialValues,

        validationSchema: Yup.object({
            firstName: Yup.string().required("This field is required"),
            familyName: Yup.string().required("This field is required"),
            title: Yup.string().required("This field is required"),
            email: Yup.string().matches(
                /\b[\w\.-]+@[\w\.-]+\.\w{2,4}\b/,
                "A valid email is required"
            ).required("This field is required"),
            active: Yup.boolean().required(),
            username: Yup.string().when("active", {
                is: (active) => active || !allowWithoutCredentials || (editMode && existingCredentials),
                then: () => Yup.string().required(),
                otherwise: () => Yup.string().nullable()
            }),
            password: Yup.string().when("active", {
                is: (active) => !editMode && active,
                then: () => Yup.string().required("This field is required").matches(
                    /(?!.*[`'"\\])(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{12,}/,
                    "Your new password must contain at least 12 characters with 1 lowercase letter, 1 uppercase and one number. Banned characters are \\ ' \" and `"),
                otherwise: () => Yup.string().nullable()
            })
        }),

        onSubmit: async (values) => {

            values.password = values.password?.trim()
            setError("")
            setSubmitting(true)

            return await handleSubmit(values).then(() => {
                onSuccess()
            }).catch((response) => {

                setError(response.data?.detail ? response.data?.detail : "Something went wrong!")
                response.errors && formik.setErrors(response.errors)

            }).finally(() => setSubmitting(false))

        }
    })

    if (hidden) return null

    return (
        <form autoComplete="off" className={`form ${submitting ? "disabled" : ""}`} onSubmit={formik.handleSubmit}>

            {guidance ? <small>{guidance}</small> : <></>}

            <div className="form-fields" style={{maxHeight: "60vh"}}>
                <Field.Text
                    formik
                    label="First name"
                    name="firstName"
                    autoComplete={false}
                    value={formik.values.firstName}
                    warning={formik.submitCount && formik.errors.firstName ? formik.errors.firstName : false}
                    onChange={formik.handleChange} autoFocus={true} />

                <Field.Text
                    formik
                    label="Last name"
                    name="familyName"
                    autoComplete={false}
                    value={formik.values.familyName}
                    warning={formik.submitCount && formik.errors.familyName ? formik.errors.familyName : false}
                    onChange={formik.handleChange}  />

                <Field.Select
                    formik
                    label="Title"
                    name="title"
                    autoComplete={false}
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    warning={formik.submitCount && formik.errors.title ? formik.errors.title : false}
                    options={DisplayDictionary("title", true).filter(option => !["*", "OTH"].includes(option.value))}  />

                <Field.Text
                    formik
                    label="Email"
                    autoComplete={false}
                    value={formik.values.email}
                    warning={formik.submitCount && formik.errors.email ? formik.errors.email : false}
                    onChange={(e) => {
                        formik.setFieldValue("email", e.target.value)
                    }} />

                {initialValues.uid ? (
                    <Field.Text
                        formik
                        label="Phone"
                        name="phone"
                        autoComplete={false}
                        value={formik.values.phone}
                        warning={formik.submitCount && formik.errors.phone ? formik.errors.phone : ""}
                        onChange={formik.handleChange}  />
                ) : null}

                <Field.Switch
                    formik
                    name="active"
                    label={(!editMode && allowWithoutCredentials) || (editMode && !existingCredentials) ? "Add credentials" : "User is able to login"}
                    helpText={(!editMode && allowWithoutCredentials) || (editMode && !existingCredentials) ? "This will allow people to sign in as this user." : ""}
                    value={formik.values.active}
                    warning={false}
                    onChange={() => {
                        if (!editMode) {
                            formik.setFieldValue("username", null)
                            formik.setFieldValue("password", null)
                        }
                        formik.setFieldValue("active", !formik.values.active)
                    }} />

                {formik.values.active || !allowWithoutCredentials || (editMode && existingCredentials) ? (
                    <>
                        <Field.Switch
                            formik
                            label="Account manager"
                            name="isAccountManager"
                            warning={false}
                            value={formik.values.isAccountManager}
                            onChange={formik.handleChange} />

                        <Field.Text
                            formik
                            label="Username"
                            value={formik.values.username}
                            warning={formik.submitCount && formik.errors.username ? formik.errors.username : false}
                            onChange={(e) => {
                                formik.setFieldValue("username", e.target.value)
                            }} />

                        {(!editMode) ? (
                            <div style={{display: "grid", alignItems: "baseline", gridTemplateColumns: "auto 30px"}}>
                                <Field.Text
                                    formik
                                    label="Password"
                                    type={revealPasswordText ? "text" : "password"}
                                    value={formik.values.password}
                                    warning={formik.submitCount && formik.errors.password ? formik.errors.password : false}
                                    onChange={(e) => {
                                        formik.setFieldValue("password", e.target.value)
                                    }} />
                                <Button className="btn btn-md btn-icon" color="info" onClick={() => setRevealPasswordText(!revealPasswordText)}>
                                    {revealPasswordText ? <Icons.Hide /> : <Icons.Show />}
                                </Button>
                            </div>
                        ) : null}
                    </>
                ) : null}
            </div>

            {error ? <div className="form-message warning">{error}</div> : null}

            <div className="form-buttons">
                <Button
                    color="primary"
                    className="btn btn-sm"
                    onClick={() => handleCancel()}>
                    Cancel
                </Button>

                <Button
                    color="primary"
                    variant="contained"
                    className="btn btn-sm"
                    type="submit">
                    Submit
                </Button>
            </div>
        </form>
    )
}