import Ajax from '../Ajax'
import { store } from './Store'
import { Theme } from '../misc/Theme'
import { Icons } from '../misc/Icons'
import { Alert, AlertModal } from '..'
import { makeAutoObservable } from 'mobx'
import { Enquiry } from '../models/Enquiry'
import { CtaInfo } from '../models/Interfaces'
import EnquiryResumeForm from '../components/form/enquiry/EnquiryResumeForm'


export class EnquiryStore {

    constructor() {
        makeAutoObservable(this)
    }

    get enquiry () {

        if (store.NodeStore.leafNode?.typeName === "Aora.Platform.Data.EnquiryNode"){
            return store.NodeStore.leafNode
        }

        return null
    }

    get enquiryStatuses () {

        var statuses = this.enquiry?.childrenObjects?.find(s => s.name === "STATUSES")

        if (statuses) {
            return statuses.childrenObjects
        }

        return null
    }

    getQualifyingRule = () => {

        var statuses = this.enquiry?.childrenObjects?.find(s => s.name === "STATUSES")
        
        if (statuses) {
            var positive_statuses = statuses.childrenObjects.filter(status => status.value === "Y")
            
            for (var s = 0; s < positive_statuses.length; s++) {
                var status = positive_statuses[s]
                return status.qualifyingRule
            }
        }

        return null
    }

    getSubject (enquiry: Enquiry) {

        if (enquiry?.subjects?.length) {
            return enquiry?.subjects[0]
        }

        return {name: "No subject", uid: ""}
    }

    rerun = () => {
        Ajax.Enquiry.Rerun(this.enquiry.uid)
    }

    setEnquiry (enquiry: Enquiry) {

        store.NodeStore.setLeafNode(enquiry)

        if (enquiry) {
            var rSection = enquiry.childrenObjects.find(section => section.name === "REPORTS")
            if (rSection) {
                store.ReportStore.setReports(rSection.childrenObjects)
            }
        }
    }

    color (enquiry: Enquiry): string {

        var value = Theme.palette.info.contrastText

        if (enquiry?.status === "DEFINITIVE" || enquiry?.status === "FINAL") {
            value = Theme.palette.info.contrastText
        }
        if (enquiry?.status === "RERUN_REQUIRED" || enquiry?.status === "REPORT_DATA_REQUIRED") {
            value = Theme.palette.warning.main
        }

        return value
    }

    outcomeColor = (outcomeCode): string => {
        if (outcomeCode === "Y") {
            return Theme.palette.success.main
        } else if (outcomeCode === "N") {
            return Theme.palette.error.main
        }
        return Theme.palette.info.contrastText
    }

    outcomeIcon = (outcomeCode, fontSize = "14px"): React.ReactElement => {
        if (outcomeCode === "Y") {
            return <Icons.Positive style={{fontSize: fontSize}} />
        } else if (outcomeCode === "N") {
            return <Icons.Negative style={{fontSize: fontSize}} />
        }
        return <></>
    }
}