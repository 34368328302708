import React from "react"
import * as Yup from 'yup'
import { Button } from "@mui/material"
import { format, isValid } from "date-fns"
import { store } from "../../../stores/Store"
import Field from "../../form/fields/Field"
import { Icons } from "../../../misc/Icons"
import Ajax from "../../../Ajax"
import { Theme } from "../../../misc/Theme"
import { useFormik } from "formik"
import { Alert } from "../../.."


export default function QuestionnaireDetails ({questionnaire, showDetails = true, height = "300px", editable = false}) {

    const {isHidden, getFieldValue, getPageList, formatPlaceholders} = store.QuestionnaireStore

    var template = JSON.parse(questionnaire.template.json)
    var answers = JSON.parse(questionnaire.answers)

    var pagesSubmitted = getPageList(template.pages, answers, true)
    var pagesRemaining = getPageList(template.pages, answers, false)
    var updatedAt = new Date(questionnaire.updatedAt)
    var updatedAtDisplay = isValid(updatedAt) ? format(updatedAt, "yyyy-MM-dd HH:mm") : updatedAt.toString()
    // var link = `https://questionnaire-dev.aoralaw.com/${questionnaire.respondent.advisorUid}/${questionnaire.uid}`

    return (
        <div className="questionnaire-details">

            {showDetails ? (
                <div style={{display: "grid", gap: "8px"}}>
                    {/* <div>
                        {editable 
                            ? <QuestionnaireName questionnaire={questionnaire} />
                            : <p style={{margin: "0"}}>{questionnaire.displayName ? questionnaire.displayName : questionnaire.uid}</p>}
                    </div> */}

                    <div style={{display: "grid", gridTemplateColumns: "120px auto", alignItems: "center", fontSize: "12px", gap: "5px"}}> 
                        <span>Reference/label:</span>
                        <span><QuestionnaireName questionnaire={questionnaire} /></span>
                        {!store.AppStore.checkJwtRoles(["client_account", "sponsor_account"], false) ? (
                            <>
                                <span>Sponsor access:</span>
                                <span><QuestionnaireAdvisor questionnaire={questionnaire} /></span>
                            </>
                        ) : null}
                        <span>Questionnaire:</span>
                        <span>{questionnaire.template.displayName} <span style={{color: "lightgrey", marginLeft: "10px"}}>{questionnaire.template.key}</span></span>
                        <span>Access link:</span>
                        <span>
                            <a 
                                className="link" 
                                rel="noreferrer"
                                target="_blank"
                                style={{fontSize: "12px"}}
                                href={questionnaire.link}>
                                {questionnaire.link}
                            </a>
                        </span>
                        <span>Access code:</span>
                        <span style={{display: "flex", alignItems: "center", gap: "10px", fontFamily: "monospace", color: "#484848"}}>
                            <b>{questionnaire.accessCode}</b> 
                            <Button className="btn btn-xs" variant="contained" color="info" onClick={() => navigator.clipboard.writeText(questionnaire.accessCode)}>Copy access code</Button>
                        </span>
                        <span>Advisor email:</span>
                        {questionnaire.notificationEmail ? (
                            <span>{questionnaire.notificationEmail} (will be notified on completion).</span>
                        ) : (
                            <span>No advisor email set up for notifications.</span>
                        )}
                        <span>Respondent email:</span>
                        <span>{questionnaire.respondent.respondentEmail}</span>
                        <span>Last updated:</span>
                        <span>{updatedAtDisplay} ({pagesSubmitted?.length} of {pagesSubmitted?.length + pagesRemaining?.length} pages completed)</span>
                    </div>
                </div>
            ) : null}

            <div className="questionnaire-pages" style={{maxHeight: height, display: "grid", overflow: "auto", border: "1.5px solid #cfcfcf", backgroundColor: "#f4f4f4", margin: "10px 0 0 0"}}>
                {pagesSubmitted.length ? pagesSubmitted?.map((page, p) => (
                    <div key={p} style={{display: "grid", margin: "5px"}}>
                        <h5>{formatPlaceholders(page.title, answers)}</h5>

                        {page.fields?.filter(field => {
                            return !isHidden(field, answers)
                        }).map((field, f) => <QuestionnaireField field={field} answers={answers} uploads={questionnaire.uploads} />)}

                        {page.table ? answers[page.table?.list_name]?.map((tableEntryAnswers, i) => (
                            <div key={i} style={{margin: "2px 0"}}>
                                {page.table.fields?.filter(field => {
                                    return !isHidden(field, answers)
                                }).map((field, f) => <QuestionnaireField field={field} answers={tableEntryAnswers} uploads={questionnaire.uploads} />)}
                            </div>
                        )) : null}
                    </div>   
                )) : (
                    <small>This questionnaire hasn't been started yet.</small>
                )}
            </div>
        </div>
    )
}


function QuestionnaireField ({field, answers, uploads}) {

    const { getFieldValue, formatPlaceholders} = store.QuestionnaireStore

    var value = getFieldValue(field, answers)
    if (value?.includes("{")) {
        value = formatPlaceholders(value, answers)
    }

    if (field?.label?.includes("{")) {
        field.label = formatPlaceholders(field.label, answers)
    }

    if (field.type === "file") {
        var upload = uploads.find(u => u.uid === value)
        return <DownloadField field={field} upload={upload} />
    }
    
    return (
        <div style={{display: "grid", gridTemplateColumns: "50% 50%", margin: "5px 0 0 14px"}}>
            <small>{field.label}</small>
            <small>{value}</small>
        </div>
    )
}


function downloadBase64File(fileName, base64String, mimeType) {
    const downloadLink = document.createElement('a')
    downloadLink.href = `data:${mimeType};base64,${base64String}`
    downloadLink.download = fileName
    downloadLink.click()
}

const fileFormats = {
    "jpg": "image/jpeg",
    "jpeg": "image/jpeg",
    "png": "image/png",
    "bmp": "image/bmp",
    "pdf": "application/pdf"
}

function DownloadField ({field, upload}) {
    
    const [downloading, setDownloading] = React.useState(false)

    const download = async () => {
        setDownloading(true)
        
        await Ajax.Questionnaire.GetUpload(upload.uid).then((response) => {
            var mimeType = fileFormats[upload.fileFormat?.toLowerCase()]
            if (mimeType) {
                downloadBase64File(upload.displayName, response.data.data, mimeType)
            }
        }).catch((response) => {
            Alert({message: response.data?.detail ? response.data.detail : "Download failed"})
        })

        setDownloading(false)
    }

    return (
        <div style={{display: "grid", gridTemplateColumns: "50% auto 100px", margin: "5px 0 0 14px"}}>
            <small>{field.label}</small>
            <small>{upload?.displayName}</small>
            <small>
                {upload ? (
                    <Button 
                        onClick={() => download()}
                        className="btn btn-xs" 
                        color="success" 
                        variant="contained"
                        style={{marginLeft: "auto"}}>
                        Download
                    </Button>
                ) : "File unavailable"}
            </small>
        </div>
    )    
}


function QuestionnaireName ({questionnaire}) {

    // const [value, setValue] = React.useState(questionnaire.displayName)
    const [success, setSuccess] = React.useState(null)

    var formik = useFormik({

        initialValues: {
            uid: questionnaire.uid,
            displayName: questionnaire.displayName
        },

        validationSchema: Yup.object({
            displayName: Yup.string().matches(/^[ ,.@!$%#';:?()[\]~_+=À-Ö\p{Sc}\p{So}\p{Mn}\p{P}\p{Z}À-ÿ\w\n]*$/u, "You have used some illegal characters").required("You must prvide a name")
        }),

        onSubmit: async (values) => {

            var newQuestionnaire = {...questionnaire, displayName: values.displayName}

            await Ajax.Questionnaire.Update(newQuestionnaire).then(async () => {
                setSuccess(true)
                questionnaire.displayName = values.displayName
                store.QuestionnaireStore.getQuestionnaires()
            }).catch((response) => {
                setSuccess(false)
            })
        }
    })

    return (
        <form style={{width: "210px", display: "grid", gridTemplateColumns: "auto 35px"}} onSubmit={formik.handleSubmit}>
            <input
                name="displayName" 
                className="native-input native-input-xs"
                value={formik.values.displayName} 
                onChange={(e) => {
                    formik.handleChange(e)
                    setSuccess(null)
                }} />

            <Button 
                color={formik.values.displayName === questionnaire.displayName ? "info" : "success"}
                variant="contained"
                className="btn btn-xs" 
                disabled={formik.isSubmitting || formik.values.displayName === questionnaire.displayName}
                type="submit">
                {/* <Icons.Save /> */}
                {success ? "Saved" : "Save"}
            </Button>
        </form>
    )
}


function QuestionnaireAdvisor ({questionnaire}) {

    // const [value, setValue] = React.useState(questionnaire.respondent.advisorUid)
    const [success, setSuccess] = React.useState(null)

    const userAccount = store.NodeStore.accounts.find((a) => a.uid === store.AppStore.sessionInfo.userAccount.uid)

    var formik = useFormik({

        initialValues: {
            uid: questionnaire.uid,
            advisorUid: questionnaire.respondent.advisorUid
        },

        validationSchema: Yup.object({
            advisorUid: Yup.string().required()
        }),

        onSubmit: async (values) => {
           
            var newQuestionnaire = {...questionnaire}
            newQuestionnaire.respondent.advisorUid = values.advisorUid
           
            await Ajax.Questionnaire.Update(newQuestionnaire).then(async () => {
                setSuccess(true)
                questionnaire.respondent.advisor = values.advisorUid
                store.QuestionnaireStore.getQuestionnaires()
            }).catch((response) => {
                setSuccess(false)
            })
        }
    })

    var clientOptions = store.NodeStore.accounts.filter((a) => !!a.advisorUid && ["sponsor", "client"].includes(a.accountType)).map((a) => {
        return {value: a.advisorUid, label: a.label}
    })

    return (
        <form style={{width: "210px", display: "grid", gridTemplateColumns: "auto 35px"}} onSubmit={formik.handleSubmit}>
            <select
                name="advisorUid" 
                className="native-input native-input-xs"
                value={formik.values.advisorUid} 
                onChange={(e) => {
                    formik.handleChange(e)
                    setSuccess(null)
                }} >
                <option value={userAccount.advisorUid}>No sponsor access</option>
                
                {clientOptions.map((option, i) => (
                    <option value={option.value}>{option.label}</option>
                ))}
            </select>

            <Button 
                color={formik.values.advisorUid === questionnaire.advisorUid ? "info" : "success"}
                variant="contained"
                className="btn btn-xs" 
                disabled={formik.isSubmitting || formik.values.advisorUid === questionnaire.respondent.advisorUid}
                type="submit">
                {/* <Icons.Save /> */}
                {success ? "Saved" : "Save"}
            </Button>
        </form>
    )
}