import { useState } from "react"
import TestForm from "../../form/TestForm"
import Field from "../../form/fields/Field"
import { DisplayDictionary } from "../../.."
import { FormFieldOutput } from "../../../models/Interfaces"
import QuestionnaireSelectMultipleField from "../../form/questionnaire/QuestionnaireSelectMulitpleField"


export default function DemoForms () {

    const [date, setDate] = useState("12/11/1996")
    const [select, seetSelect] = useState("")
    const [files, setFiles] = useState<File[]>([])

    const [multipleSelect, setMultipleSelect] = useState([])
    const [checkboxSelect, setCheckboxSelect] = useState([])

    const [questionnaires, setQuestionnaires] = useState([])

    const setSelect = (value) => {
        console.log("Select", value)
        seetSelect(value)
    }

    return (
        <div>
            <div style={{maxWidth: "700px"}}>
                <QuestionnaireSelectMultipleField value={questionnaires} onChange={(value) => {
                    console.log(value)
                    setQuestionnaires(value)
                }} />
            </div>

            <br/><br/>
            <hr/>
            <br/><br/>

            <div className="settings-section form-demo">

                <TestForm />

                <div className="form">
                    <h3>Date field testing</h3>
                    <div className="form-fields">
                        <Field.Date
                            formik
                            label="Enter a date"
                            name="date"
                            value={date}
                            openDateCheckbox={{label: "Christmas day", value: "2022-12-25"}}
                            onChange={(e) => setDate(e.target.value)} />

                        <div className="help-text">{date?.toString()}</div>
                    </div>
                </div>


                <div>
                    <Field.LargeListSelect
                        name="select"
                        value={multipleSelect}
                        label="Large list select"
                        noneOption={true}
                        onChange={(fieldOutput: FormFieldOutput) => setMultipleSelect(fieldOutput.value)}
                        options={DisplayDictionary("countries", true)}
                    />

                    <Field.MinimisedLargeListSelect
                        name="select"
                        value={multipleSelect}
                        label="Which country are you applying from?"
                        onChange={(fieldOutput: FormFieldOutput) => setMultipleSelect(fieldOutput.value)}
                        options={DisplayDictionary("countries", true)}
                    />

                    <br/>

                    <Field.FileMultiple
                        label="Select a file"
                        value={files}
                        onChange={(fieldOutput: FormFieldOutput) => {
                            console.log(fieldOutput, files)
                            setFiles(fieldOutput.value)
                        }}
                        />
                </div>

                <div>
                    <Field.Radio
                        name="select"
                        value={select}
                        label="All we hear is"
                        placeholder="Select something"
                        onChange={(fieldOutput: FormFieldOutput) => setSelect(fieldOutput.value)}
                        options={[
                            {value: "Radio Ga Ga", label: "Radio Ga Ga"},
                            {value: "Radio Goo Goo", label: "Radio Goo Goo"}
                        ]}/>

                    {/* <Field.SliderSelect
                        name="select"
                        value={select}
                        onChange={(fieldOutput: FormFieldOutput) => setSelect(fieldOutput.value)}
                        options={[
                            {value: "Radio Ga Ga", label: "Radio Ga Ga"},
                            {value: "Radio Goo Goo", label: "Radio Goo Goo"}
                        ]}/> */}

                    <br/>

                    <Field.CheckboxSelect
                        label="Checkbox select"
                        value={checkboxSelect}
                        onChange={(fieldOutput: FormFieldOutput) => setCheckboxSelect(fieldOutput.value)}
                        options={[
                            {label: "Option1", value: "1"},
                            {label: "Option2", value: "2"},
                            {label: "Option3", value: "3", children: [
                                {label: "Option3.1", value: "3.1"},
                                {label: "Option3.2", value: "3.2"},
                                {label: "Option3.3", value: "3.3", children: [
                                    {label: "Option3.3.1", value: "3.31"},
                                    {label: "Option3.3.2", value: "3.32"},
                                    {label: "Option3.3.3", value: "3.33"},
                                ]},
                            ]},
                        ]} />

                </div>
            </div>
        </div>
    )
}
