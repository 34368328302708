import React from "react"
import * as Yup from 'yup'
import { Button } from "@mui/material"
import { useFormik } from "formik"
import { store } from "../../../stores/Store"
import Field from "../fields/Field"


export default function AutomationEmailSection ({
    automationOption,
    automationData,
    hidden = false,
    includeSubjectEmail = true,
    onCancel = () => {},
    onSubmit = async (values) => {}
}) {

    // Because this whole component is mounted before additional data is populated with the subject's email,
    // we need to update the formik value afterwards when the props update.
    React.useEffect(() => {
        if (automationData?.subject_email) {
            formik.setFieldValue("subject_email", automationData?.subject_email)
        }
    }, [automationData?.subject_email])
    
    var formik = useFormik({
        initialValues: {
            advisor_email: store.AppStore.sessionInfo.userEmail,
            subject_email: automationData?.subject_email,
        },
        validationSchema: Yup.object({
            advisor_email: Yup.string().matches(/\b[\w\.-]+@[\w\.-]+\.\w{2,4}\b/u, "A valid email is required").required("This field is required"),
            subject_email: includeSubjectEmail 
                ? Yup.string().matches(/\b[\w\.-]+@[\w\.-]+\.\w{2,4}\b/u, "A valid email is required").required("This field is required")
                : Yup.string()


        }),
        onSubmit: async (values) => {
            values.advisor_email = values.advisor_email.toLowerCase()
            values.subject_email = values.subject_email.toLowerCase()
            await onSubmit(values)
        }
    })

    if (hidden) return <></>

    return (
        <form className="form" onSubmit={formik.handleSubmit}>
            <div className="form-fields">
                <div>
                    <p>{automationOption.label}</p>
                    <p>Correspondence with the Home Office will go via the email address{includeSubjectEmail ? "es" : ""} entered here.</p>
                </div>

                <div style={{maxWidth: "360px"}}>
                    <Field.Text
                        formik
                        label="Advisor email"
                        name="advisor_email"
                        value={formik.values.advisor_email}
                        warning={formik.submitCount && formik.errors.advisor_email ? formik.errors.advisor_email : null}
                        onChange={formik.handleChange}  />

                    {includeSubjectEmail ? (
                        <Field.Text
                            formik
                            label="Applicant email"
                            name="subject_email"
                            helpText="Enter the applicant's or preferred alternative email address to be used in the form."
                            value={formik.values.subject_email}
                            warning={formik.submitCount && formik.errors.subject_email ? formik.errors.subject_email : false}
                            onChange={formik.handleChange}  />
                    ) : null}
                </div>
            </div>

            <div className="form-buttons">
                <Button className="btn btn-sm" onClick={() => onCancel()}>Back</Button>

                <Button 
                    disabled={formik.isSubmitting} 
                    className="btn btn-sm" 
                    type="submit" 
                    color="success" 
                    variant="contained">
                    Confirm and start automation
                </Button>
            </div>
        </form>
    )
}