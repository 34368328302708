
import Logo from '../../misc/Logo'
import { Link } from 'react-router-dom'
import { Icons } from '../../misc/Icons'
import { store } from '../../stores/Store'
import TruncText from '../../misc/TruncText'
import { IconButton, Button } from '@mui/material'
import SystemInformation from '../../misc/SystemInformation'
import { observer } from 'mobx-react-lite'

interface MainMenuProps {
    handleClose: Function;
}

export default observer(function MainMenu({handleClose}: MainMenuProps) {

    var userAccountUid = store.AppStore.sessionInfo.userAccount?.uid
    var account = store.NodeStore.accounts?.find(a => a.uid === userAccountUid)

    if (!account) return <></>

    return (
        <div className="main-menu">
            <div className="main-menu-header">
                <div className="header">
                    <Button component={Link} to="/app/main" className="btn">
                        <Logo type="block" height="24px" color="#c0c0c0" />
                    </Button>
                    <IconButton size="small" onClick={() => handleClose()}>
                        <Icons.X />
                    </IconButton>
                </div>
                <TruncText text={<small>{account.accountName}</small>} />
            </div>

            <div className="main-menu-body">
                <nav className="main-menu-nav">
                    {/* {!store.AppStore.checkJwtRoles(["client_account", "sponsor_account"], false) ? (
                        <> */}
                            <Button className="main-menu-link" onClick={() => store.ToolStore.tool = "account"}>
                                <Icons.Account fontSize="small" />
                                <span>Account</span>
                            </Button>

                            {store.AppStore.checkJwtRoles(["sysuser", "customer_account"], false) ? (
                                <Button className="main-menu-link" onClick={() => {
                                    if (store.ToolStore.tool != "client") {
                                        store.ToolStore.tool = "client"
                                    } else {
                                        store.ToolStore.setClient(null)
                                    }
                                }}>
                                    <Icons.Account fontSize="small" />
                                    <span>Clients</span>
                                </Button>
                            ) : null}

                            <Button className="main-menu-link" onClick={() => store.ToolStore.tool = "questionnaire"}>
                                <Icons.Questionnaire fontSize="small" />
                                <span>Questionnaires</span>
                            </Button>

                            {store.AppStore.checkJwtRole("sysuser") ? (
                                <Button className="main-menu-link" onClick={() => store.ToolStore.tool = "template"}>
                                    <Icons.Questionnaire fontSize="small" />
                                    <span>Questionnaire Templates</span>
                                </Button>
                            ) : null}

                            {store.AppStore.checkJwtRole("account_manager") && account.advisorUid ? (
                                <Button className="main-menu-link" onClick={() => store.ToolStore.tool = "report"}>
                                    <Icons.Report fontSize="small" />
                                    <span>Reports</span>
                                </Button>
                            ) : null}

                            <Button className="main-menu-link" onClick={() => store.ToolStore.tool = "profile"}>
                                <Icons.User fontSize="small" />
                                <span>My Details</span>
                            </Button>

                            {/* {store.AppStore.checkJwtRole("account_manager") ? (
                                <Button className="main-menu-link" component={Link} to="/app/report-management">
                                    <Icons.Report fontSize="small" />
                                    <span>Report Management</span>
                                </Button>
                            ) : null} */}

                            {/* <Button className="main-menu-link" href="https://file.aoralaw.com/images/Aora ND Getting Started.pdf" target="_blank" rel="noreferrer">
                                <Icons.Open fontSize="small" />
                                <span>Getting Started: Nationality</span>
                            </Button>
                            
                            <Button className="main-menu-link" href="https://file.aoralaw.com/images/AORA SWV Getting Started.pdf" target="_blank" rel="noreferrer">
                                <Icons.Open fontSize="small" />
                                <span>Getting Started: SWV</span>
                            </Button> */}

                            {/* <Button className="main-menu-link" href="https://file.aoralaw.com/images/Aora ND User Guide WEB v1.1.pdf" target="_blank" rel="noreferrer">
                                <Icons.Open fontSize="small" />
                                <span>User Guide</span>
                            </Button> */}

                            <Button className="main-menu-link" href="https://www.aoralaw.com/resources.php" target="_blank" rel="noreferrer">
                                <Icons.Open fontSize="small" />
                                <span>Resources</span>
                            </Button>

                            {process.env.NODE_ENV === "development" ? (
                                <Button className="main-menu-link" component={Link} to="/app/ui-demo">
                                    <Icons.Component fontSize="small" />
                                    <span>UI Testing</span>
                                </Button>
                            ) : null}
                        {/* </>
                    ) : null} */}

                    <Button className="main-menu-link" onClick={() => {store.AppStore.logout()}}>
                        <Icons.Exit fontSize="small" />
                        <span>Logout</span>
                    </Button>
                </nav>
            </div>
            <div className="main-menu-footer">
                <SystemInformation key={store.ConnectionStore.connectionState} />
            </div>
        </div>
    )
})