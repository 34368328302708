import * as Yup from 'yup'
import { Button } from "@mui/material"
import { Alert } from "../../.."
import Field from "../fields/Field"
import { store } from "../../../stores/Store"
import { useFormik } from "formik"


const formatJson = (json: string): string => {
    return JSON.stringify(JSON.parse(json), null, "    ")
}


const jsonPlaceholder = '{\n    "name": "My Questionnaire",\n    "pages": [\n        {\n            "title": "First Page",\n            "description": "<p>Enter your information here</p>",\n            "fields": [\n                {\n                    "name": "best_wrap_of_the_day"\n                    ...'


export default function TemplateForm ({
    template = {
        key: "",
        displayName: "",
        advisorUid: "",
        json: "",
        active: true,
    },
    title = null,
    guidance = null,
    onCancel = () => store.AlertStore.Close(),
    onSubmit,
}) {

    const { assignPageIDs } = store.QuestionnaireStore
    const advisorOptions = [{value: "GLOBAL", label: "Global (Available for all accounts)"}]
    store.NodeStore.accounts.filter(a => !!a.advisorUid).forEach((account) => {
        advisorOptions.push({value: account.advisorUid, label: account.accountName})
    })
    const keyEditable = !template.key

    var formik = useFormik({
        initialValues: {
            key: template.key,
            displayName: template.displayName,
            advisorUid: template.advisorUid,
            json: template.json ? formatJson(template.json) : "",
            active: template.active,
        },

        validationSchema: Yup.object({
            key: Yup.string().required("This field is required"),
            displayName: Yup.string().matches(/^[ ,.@!$%#';:?()[\]~_+=À-Ö\p{Sc}\p{So}\p{Mn}\p{P}\p{Z}À-ÿ\w\n]*$/u, "You have used some illegal characters").required("This field is required"),
            // json: Yup.string().matches(/^\{(.*\n*)*\}$/u, "You must enter valid JSON here").required("This field is required"),
            active: Yup.string().required("This field is required"),
        }),

        onSubmit: async (values, actions) => {
            await assignPageIDs(formik.values.json).then(async (templateJson) => {
                onSubmit({...template, ...values, json: templateJson})
                formik.setFieldValue("json", templateJson)
            }).catch(() => {
                Alert({message: "Invalid JSON"})
            })
        }
    })

    return (
        <form className="form questionnaire-template-detail" onSubmit={formik.handleSubmit}>
            <div className="header">
                {title ? <h2>{title}</h2> : null}
                
                {guidance ? <h2>{guidance}</h2> : null}

                <Field.Switch
                    formik
                    label="Active"
                    name="active"
                    value={formik.values.active}
                    onChange={formik.handleChange} />
            </div>

            <div className="form-fields">
                <div className="form-fields" style={{maxWidth: "350px"}}>
                    <Field.Text
                        formik
                        label="Template key"
                        name="key"
                        disabled={!keyEditable}
                        value={formik.values.key}
                        warning={formik.submitCount && formik.errors.key ? formik.errors.key : false}
                        onChange={formik.handleChange} />

                    <Field.Text
                        formik
                        label="Template name"
                        name="displayName"
                        value={formik.values.displayName}
                        warning={formik.submitCount && formik.errors.displayName ? formik.errors.displayName : false}
                        onChange={formik.handleChange} />

                    <Field.Select
                        formik
                        label="Exclusive access"
                        name="advisorUid"
                        value={formik.values.advisorUid}
                        onChange={formik.handleChange}
                        warning={formik.submitCount && formik.errors.advisorUid ? formik.errors.advisorUid : false}
                        options={advisorOptions}
                        helpText="If an account is selected, this template will only be available for this account. Otherwise, the template will be visible to all accounts on this instance." />
                </div>

                <div style={{display: "grid"}}>
                    <textarea
                        name="json"
                        placeholder={jsonPlaceholder}
                        value={formik.values.json}
                        rows={10}
                        onChange={formik.handleChange} />

                    {formik.submitCount && formik.errors.json ? (
                        <div className="warning">{formik.errors.json}</div>
                    ) : null}
                </div>
            </div>

            <div className="form-buttons">
                <div className="form-buttons">
                    <Button className="btn btn-sm" onClick={() => onCancel()}>Cancel</Button>
                    <Button
                        className="btn btn-sm"
                        onClick={() => {
                            assignPageIDs(formik.values.json).then(templateJson => {
                                formik.setFieldValue("json", templateJson)
                            }).catch(() => {
                                Alert({message: "Invalid JSON"})
                            })
                        }}>
                        Assign page IDs
                    </Button>
                </div>

                <Button
                    color="success"
                    variant="contained"
                    className="btn btn-sm"
                    type="submit"
                    disabled={formik.isSubmitting}>
                    Submit
                </Button>
            </div>
        </form>
    )
}