import { Theme } from "../../../../../misc/Theme";

export default function EnquiryOutcomeDetails ({enquiry}) {

    const {salary_data, events} = enquiry.additionalOutcomeData

    return (
        <div>
            {salary_data?.rows?.length ? (
                <SalaryTable salaryData={salary_data} />
            ) : null}

            {events ? (
                <StatusTrace eventsXml={events} />
            ) : null}
        </div>
    )
}

function StatusTrace ({eventsXml}) {

    var events = []
    var footnotes = []

    try {
        const parser = new DOMParser()

        eventsXml?.forEach((e) => {
            const xmlDoc = parser.parseFromString(e.replace("&", "and"), "text/xml")

            
            const fns = xmlDoc.getElementsByTagName("footnote")
            for (var i=0; i<=fns.length; i++) {
                if (fns[i]) {
                    footnotes.push(fns[i]?.textContent)
                    fns[i].textContent = `[${footnotes.length}]`
                }
            }

            const evs = xmlDoc.getElementsByTagName("event")
            for (var i=0; i<=evs.length; i++) {
                events.push(evs[i]?.textContent)
            }
        })
    } catch (e) {
        events = ["There was an error when processing the outcome details."]
    }

    return (
        <div>
            <div>
                {events.map((event, i) => (
                    <p style={{whiteSpace: "break-spaces"}}>{event}</p>
                ))}
            </div>

            <ol style={{fontSize: "12px"}}>
                {footnotes.map((fn, i) => (
                    <li>{fn}</li>
                ))}
            </ol>
        </div>
    )
}

function SalaryTable ({salaryData}) {

    var columnWidths = "70px 200px 90px 160px 130px"

    return (
        <div style={{display: "grid", background: "#e8e8e8", padding: "5px", gap: "7px"}}>
            <div style={{display: "grid", gridTemplateColumns: columnWidths, fontWeight: "600"}}>
                <span>Option</span>
                <span>Applicant Conditions</span>
                <span>Notes</span>
                <span>Minimum salary</span>
                <span>Outcome</span>
            </div>

            {salaryData.rows.map((row, i) => (
                <div style={{display: "grid", gridTemplateColumns: columnWidths}}>
                    <span>{row.option}</span>
                    <span>{row.conditions_desc}</span>
                    <span>{row.condition_refs}</span>
                    <span>{row.min_salary}</span>
                    <span style={{color: row.outcome ? Theme.palette.secondary.main : Theme.palette.error.main}}>
                        <b>{row.outcome ? "Contingent Pass" : "Fail"}</b>
                    </span>
                </div>
            ))}
        </div>
    )
}