import React from "react"
import { observer } from "mobx-react-lite"
import { Button, IconButton, LinearProgress } from "@mui/material"
import { Icons } from "../../../misc/Icons"
import { store } from "../../../stores/Store"
import PanelBody from "../../layout/PanelBody"
import AccountSelect from "../main/AccountSelect"
import PanelHeader from "../../layout/PanelHeader"
import AccountUsers from "./AccountUsers"
import AccountDetails from "./AccountDetails"
import IsAllowed from "../../../models/Permissions"
import { AlertModal } from "../../.."
import { UserCreateForm } from "../../form/admin/UserForms"
import { AccountEditForm } from "../../form/admin/AccountForms"
import DeleteForm from "../../form/case/DeleteForm"


export default observer(function AccountMgmtTool() {    
    
    const { sessionState } = store.AppStore
    const {account, getAccount, setTool} = store.ToolStore
    const [loading, setLoading] = React.useState(true)
    
    React.useEffect(() => {
        if (!account) {
            getAccount().then(() => {
                setLoading(false)
            })
        } else {
            setLoading(false)
        }
    }, [])
    
    return (
        <div className="panel panel-dark">
            <PanelHeader>
                <div className="header">
                    <h2>Account management</h2>
                </div>

                <IconButton style={{marginLeft: "auto"}} size="medium" onClick={() => setTool(null)}><Icons.X /></IconButton>
            </PanelHeader>

            <PanelBody>
                {!account || loading ? (
                    <LinearProgress color="primary" />
                ) : (
                    <div style={{display: "grid", gap: "20px", overflowY: "scroll", paddingRight: "20px"}}>
                        <AccountDetails account={account} />

                        {account?.accountType !== "admin" && IsAllowed(account, "UPDATE") ? (
                            <Button
                                color="info"
                                variant="contained"
                                className="btn btn-sm"
                                disabled={!!sessionState}
                                onClick={() => {
                                    AlertModal({title: `Edit account`, body: (
                                        <AccountEditForm
                                            account={account}
                                            onSuccess={() => {
                                                store.AlertStore.Close()
                                                getAccount()
                                            }}
                                        />
                                    )})
                                }}>
                                Edit account
                            </Button>
                        ) : null}

                        <AccountUsers account={account} />

                        {IsAllowed("User", "CREATE") ? (
                            <Button
                                color="info"
                                variant="contained"
                                className="btn btn-sm"
                                disabled={!!sessionState}
                                onClick={() => {
                                    AlertModal({body: (
                                        <UserCreateForm
                                            account={account} 
                                            onSuccess={() => {
                                                getAccount(account.uid)
                                                store.AlertStore.Close()
                                        }} />
                                    )})
                                }}>
                                Create new user
                            </Button>
                        ) : null}

                        <br/>
                    </div>
                )}
            </PanelBody>
        </div>
    )
})