import Ajax from '../Ajax'
import { store } from './Store'
import { User } from '../models/User'
import Account, { SponsorAccount } from '../models/Account'
import { makeAutoObservable } from 'mobx'
import { Alert } from '..'


export class ToolStore {
    
    tool: string = null
    open: boolean = false
    
    account: SponsorAccount = null
    client: SponsorAccount = null
    user: User | null = null

    constructor() {
        makeAutoObservable(this)
    }

    get users () {
        var users = this.account?.childrenObjects.filter((node: any) => node.typeName === "Aora.Platform.Data.UserNode")
        return users
    }

    setOpen = (open: boolean) => {
        this.open = open
        this.tool = null
    }

    setTool = (tool: string) => {
        this.tool = tool
    }

    getAccount = async (uid = null) => {

        if (!uid) {
            uid = this.account ? this.account.uid : store.AppStore.sessionInfo.userAccount.uid
        }

        await Ajax.Node.Get(uid).then((response) => {
            this.setAccount(response.data)
        }).catch((response) => {
            Alert({message: response.data.detail})
        })
        
    }

    setAccount = (account: SponsorAccount | null) => {
        this.account = account
    }

    getClient = async (uid = null) => {

        if (!uid) {
            uid = this.account ? this.account.uid : store.AppStore.sessionInfo.userAccount.uid
        }

        await Ajax.Node.Get(uid).then((response) => {
            this.setClient(response.data)
        }).catch((response) => {
            Alert({message: response.data.detail})
        })
        
    }

    setClient = (client: SponsorAccount | null) => {
        this.client = client
    }

    getUser = async (uid = null) => {

        await Ajax.Node.Get(uid ? uid : store.AppStore.sessionInfo.userUid).then((response) => {
            this.user = response.data
        }).catch((response) => {
            Alert({message: response.data.detail})
        })
        
    }
}