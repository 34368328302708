import { observer } from "mobx-react-lite"
import { store } from "../../../stores/Store"
import { TypeIcon } from "../../../misc/Icons"
import { MenuItem, MenuList } from "@mui/material"


interface ClientListProps {
    handleSelect: Function;
}


export default observer(function ClientList ({handleSelect}: ClientListProps) {

    const {accounts} = store.NodeStore
    var customers = accounts.filter(account => account.typeName === "Aora.Platform.Data.AccountNode" && account.accountType === "customer")
    var clients = accounts.filter(account => account.typeName === "Aora.Platform.Data.AccountNode" && account.accountType === "client")
    var sponsorClients = accounts.filter(account => account.typeName === "Aora.Nationality.Data.SponsorAccountNode")

    var clientLabel = store.NodeStore.getDisplayType({typeName: "Aora.Platform.Data.AccountNode"}, true)
    var sponsorLabel = store.NodeStore.getDisplayType({typeName: "Aora.Nationality.Data.SponsorAccountNode"}, true)

    if (!clients.length && !sponsorClients.length) return <small>You haven't set up any client accounts yet.<br/><br/></small>

    if (store.AppStore.checkJwtRole("admin_account")) {

        return (
            <>
                {customers.map((customer, i) => {
                    var customerClients = clients.filter((client) => client.parent?.uid === customer.uid)
                    var customerSponsorClients = sponsorClients.filter((client) => client.parent?.uid === customer.uid)
                    return (
                        <div key={i}>
                            <MenuItem key={i} style={{padding: "4px 4px"}} onClick={() => handleSelect(customer.uid)}>
                                <TypeIcon node={customer} style={{fontSize: "18px", marginRight: "6px"}} />
                                <span>{customer.label}</span>
                            </MenuItem>

                            <div style={{margin: "5px 0 0 33px"}}>
                                <AccountListGroup groupHeader={sponsorLabel} accounts={customerSponsorClients} handleSelect={handleSelect} />
                                <AccountListGroup groupHeader={clientLabel} accounts={customerClients} handleSelect={handleSelect} />
                            </div>
                        </div>
                    )
                })}
            </>
        )
    }

    return (
        <>
            <AccountListGroup groupHeader={clientLabel} accounts={clients} handleSelect={handleSelect} />
            <AccountListGroup groupHeader={sponsorLabel} accounts={sponsorClients} handleSelect={handleSelect} />
        </>
    )
})


function AccountListGroup ({groupHeader, accounts, handleSelect}) {

    if (!accounts?.length) return <></>

    return (
        <div className="tool-section">
            <div className="tool-table">
                <small style={{color: "#adadad"}}>{groupHeader}</small>

                <MenuList>
                    {accounts.map((account, i) => (
                        <MenuItem key={i} style={{paddingLeft: 0}} onClick={() => handleSelect(account.uid)}>
                            <TypeIcon node={account} style={{fontSize: "18px", marginRight: "6px"}} />
                            <span>{account.label}</span>
                        </MenuItem>
                    ))}
                </MenuList>
            </div>
        </div>
    )
}