import { format } from "date-fns"
import { Button } from "@mui/material"
import { observer } from "mobx-react-lite"
import { store } from "../../../stores/Store"
import { AlertModal, DisplayValue } from "../../.."
import { ToolDetailRow } from "../profile_mgmt/ProfileMgmtTool"
import LogoUpdate from "../../form/admin/AccountLogoForm"
import AccountQuestionnaireEnableButton from "../../form/admin/AccountQuestionnaireEnableButton"


interface AccountDetailsProps {
    account: any;
}


export default observer(function AccountDetails ({account}: AccountDetailsProps) {

    if (account?.accountType === "admin") return <></>

    return (
        <div className="tool-section">
            <div className="tool-table">
                <ToolDetailRow label={DisplayValue("dict_key", "accountName")}                  value={account.accountName} />
                <ToolDetailRow label={DisplayValue("dict_key", "parent")}                       value={account.parent?.name} hidden={account.accountType === "customer"} />
                <ToolDetailRow label={DisplayValue("dict_key", "owner")}                        value={account.owner?.name} />
                {/* <ToolDetailRow label={DisplayValue("dict_key", "createdBy")}                    value={account.createdBy?.name} /> */}
                <ToolDetailRow label={DisplayValue("dict_key", "accountType")}                  value={store.NodeStore.getDisplayType(account)} />
                <ToolDetailRow label={DisplayValue("dict_key", "customerType")}                 value={DisplayValue("customer_type", account.customerType)} />
                {account.organisationType ? (
                    <ToolDetailRow label={DisplayValue("dict_key", "organisationType")}         value={DisplayValue("org_type", account.organisationType)} />
                ) : null}
                <ToolDetailRow label={DisplayValue("dict_key", "phone")}                        value={account.phone} />
                <ToolDetailRow label={DisplayValue("dict_key", "addressLine1")}                 value={account.addressLine1} />
                <ToolDetailRow label={DisplayValue("dict_key", "city")}                         value={account.city} />
                <ToolDetailRow label={DisplayValue("dict_key", "postcode")}                     value={account.postcode} />
                <ToolDetailRow label={DisplayValue("dict_key", "countryId")}                    value={DisplayValue("countries", account.countryId)} />
                <ToolDetailRow label={DisplayValue("dict_key", "regulator")}                    value={DisplayValue("ni_regulator", account.regulator)} hidden={account.accountType !== "customer"} />
                <ToolDetailRow label={DisplayValue("dict_key", "regulatorRegistrationNumber")}  value={account.regulatorRegistrationNumber} hidden={account.accountType !== "customer"} />

                {account.typeName === "Aora.Nationality.Data.SponsorAccountNode" ? (
                    <>
                        <ToolDetailRow label={DisplayValue("dict_key", "sponsorLicenceNum")}    value={account.sponsorLicenceNum} />
                        <ToolDetailRow label={DisplayValue("dict_key", "sponsorStartDate")}     value={account.sponsorStartDate ? format(new Date(account.sponsorStartDate), "dd/MM/yyyy") : ""} />
                        <ToolDetailRow label={DisplayValue("dict_key", "sponsorExpiryDate")}    value={account.sponsorExpiryDate ? format(new Date(account.sponsorExpiryDate), "dd/MM/yyyy") : ""} />
                    </>
                ) : null}

                {account.advisorUid ? (
                    <ToolDetailRow label={"Questionnaire Logo"}                                 value={<QuestionnaireLogoButton account={account} />} />
                ) : null}

                {store.AppStore.sessionInfo.userAccount.uid === "ACCOUNT_01" && !account.advisorUid && account.typeName !== "Aora.Nationality.Data.SponsorAccountNode" ? (
                    <ToolDetailRow label={"Questionnaires"}                               value={<AccountQuestionnaireEnableButton account={account} />} />
                ) : null}
            </div>
        </div>
    )
})



function QuestionnaireLogoButton ({account}) {

    var logoUri = "https://aora.lon1.digitaloceanspaces.com/advisor_images/"+account.advisorUid+".png?refresh=" + Math.random().toString()

    return (
        <div style={{display: "flex", gap: "10px"}}>
            <img style={{height: "22px"}} src={logoUri} alt="No image set" />

            <Button
                className="btn btn-sm"
                variant="contained"
                color="info"
                onClick={() => {
                    AlertModal({body: <LogoUpdate account={account} />})
                }}>
                Edit
            </Button>
        </div>
    )
}