
import {
    Drawer,
    IconButton
} from '@mui/material'
import React from 'react'
import MainMenu from './MainMenu'
import { Icons } from '../../misc/Icons'
import { store } from '../../stores/Store'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'
import ReportMgmtTool from '../views/report_mgmt/ReportMgmtTool'
import AccountMgmtTool from '../views/account_mgmt/AccountMgmtTool'
import ProfileMgmtTool from '../views/profile_mgmt/ProfileMgmtTool'
import TemplateTool from '../views/template_mgmt/TemplateTool'
import QuestionnaireTool from '../views/questionnaire_mgmt/QuestionnaireTool'
import ClientMgmtTool from '../views/client_mgmt/ClientMgmtTool'


export default observer(function ToolDrawer() {

    const {setOpen, open, setTool, tool} = store.ToolStore
    const params = useParams()

    React.useEffect(() => {
        setOpen(false)
        setTool(null)
    }, [params])

    document.onkeyup = (e) => {
        e.ctrlKey && e.key === "m" && setOpen(!open)
    }

    return (
        <React.Fragment>
            <IconButton
                onClick={() => {setOpen(true)}}
                color="inherit"
                aria-label="Main menu"
                className="main-menu-toggler">
                <Icons.Menu style={{color: "white"}} />
            </IconButton>
 
            <Drawer
                anchor="left"
                open={open}
                role="menu"
                PaperProps={{
                    style: {
                        boxShadow: "none",
                        backgroundColor: "transparent"
                    }
                }}
                onClose={() => {setOpen(false)}}>
                <div className={`tool-drawer ${tool ? "tool-drawer-open" : null}`}>
                    <MainMenu handleClose={() => setOpen(false)} />
                    
                    <div className="tool-drawer-body">
                        <ToolSwitch />
                    </div>
                </div>
            </Drawer>
        </React.Fragment>
    )
})


const ToolSwitch = observer(() => {
    switch (store.ToolStore.tool) {
        case "account":
            return <AccountMgmtTool />
        case "client":
            return <ClientMgmtTool />
        case "profile":
            return <ProfileMgmtTool />
        case "report":
            return <ReportMgmtTool />
        case "questionnaire":
            return <QuestionnaireTool />
        case "template":
            return <TemplateTool />
        default:
            return <></>
    }
})