import React from "react"
import { observer } from "mobx-react-lite"
import { Button, IconButton, LinearProgress } from "@mui/material"
import { Icons } from "../../../misc/Icons"
import { store } from "../../../stores/Store"
import PanelBody from "../../layout/PanelBody"
import AccountSelect, { AccountSelectMenu } from "../main/AccountSelect"
import PanelHeader from "../../layout/PanelHeader"
import { AlertModal } from "../../.."
import { AccountCreateForm, AccountEditForm, NewSponsorClientAccountForm } from "../../form/admin/AccountForms"
import ClientList from "./ClientList"
import AccountDetails from "../account_mgmt/AccountDetails"
import AccountUsers from "../account_mgmt/AccountUsers"
import IsAllowed from "../../../models/Permissions"
import DeleteForm from "../../form/case/DeleteForm"
import { UserCreateForm } from "../../form/admin/UserForms"
import AccountQuestionnaireEnableButton from "../../form/admin/AccountQuestionnaireEnableButton"


export default observer(function AccountMgmtTool() {    

    const {client, getClient, setClient, setTool} = store.ToolStore
    const {accounts, getAccounts} = store.NodeStore
    const [loading, setLoading] = React.useState(!!accounts)

    
    React.useEffect(() => {
        if (!accounts) {
            getAccounts().then(() => {
                setLoading(false)
            })
        } else {
            setLoading(false)
        }
    }, [])
    

    return (
        <div className="panel panel-dark">
            <PanelHeader>
                <div className="header">
                    <h2>Client management</h2>
                    
                    {client ? (
                        <Button 
                            className="btn-sm" 
                            color="info" 
                            variant="contained" 
                            onClick={() => setClient(null)}>
                            <Icons.ArrowLeft /> Back to client list
                        </Button>
                    ) : null}
                </div>

                <IconButton style={{marginLeft: "auto"}} size="medium" onClick={() => setTool(null)}><Icons.X /></IconButton>
            </PanelHeader>

            <PanelBody>
                <div className={loading ? "disabled" : ""} style={{display: "grid", gap: "20px", overflowY: "scroll", paddingRight: "20px"}}>
                    {client ? (
                        <ClientDetails />
                    ) : (
                        <>
                            <ClientList handleSelect={async (uid) => {
                                setLoading(true)
                                await getClient(uid)
                                setLoading(false)
                            }} />

                            <div className="button-group">
                                <Button
                                    color="info"
                                    className="btn btn-sm"
                                    variant="contained"
                                    onClick={(e) => AlertModal({title: "Create new Sponsor", body: <NewSponsorClientAccountForm />})}>
                                    Create new Sponsor Client
                                </Button>

                                {store.AppStore.checkJwtRole("admin_account") ? (
                                    <Button
                                        color="info"
                                        className="btn btn-sm"
                                        variant="contained"
                                        onClick={(e) => AlertModal({title: "Create new AORA customer", body: <AccountCreateForm initialValues={{accountType: "customer"}} onSuccess={() => {
                                            store.NodeStore.getAccounts()
                                            store.AlertStore.Close()
                                        }} />})}>
                                        Create new AORA customer
                                    </Button>
                                ) : null}
                            </div>
                        </>
                    )}
                    
                    <br/>
                </div>
            </PanelBody>
        </div>
    )
})

function ClientDetails () {
    
    const {sessionState} = store.AppStore
    const {client, getClient, setClient, setTool} = store.ToolStore

    return (
        <>  
            <AccountDetails account={client} />

            <div style={{display: "flex", gap: "10px"}}>
                <Button
                    color="info"
                    variant="contained"
                    className="btn btn-sm"
                    onClick={() => {
                        // store.NodeStore.navigateNode(client.uid)
                        store.ToolStore.setClient(null)
                        store.ToolStore.setTool(null)
                        store.ToolStore.setOpen(false)

                        store.NodeStore.setHomeNodeUid(client.uid)
                        store.NodeStore.setFolderNode(null)
                        // store.NodeStore.refreshFolderNode()
                    }}>
                    Go to workspace
                </Button>

                {IsAllowed(client, "UPDATE") ? (
                    <Button
                        color="info"
                        variant="contained"
                        className="btn btn-sm"
                        disabled={!!sessionState}
                        onClick={() => {
                            AlertModal({title: `Edit account`, body: (
                                <AccountEditForm
                                    account={client}
                                    onSuccess={() => {
                                        store.AlertStore.Close()
                                        getClient(client.uid)
                                    }}
                                />
                            )})
                        }}>
                        Edit account
                    </Button>
                ) : null}

                {IsAllowed(client, "DELETE") ? (
                    <Button
                        color="error"
                        variant="contained"
                        className="btn btn-sm"
                        disabled={!!sessionState}
                        onClick={() => {
                            AlertModal({body: <DeleteForm node={client} onSuccess={() => {
                                setTool(null)
                                setClient(null)
                                store.NodeStore.getAccounts()
                                store.NodeStore.navigateNode(null)
                            }} /> })
                        }}>
                        Delete account
                    </Button>
                ) : null}

                {!client.advisorUid ? (
                    <AccountQuestionnaireEnableButton account={client} onSuccess={() => store.ToolStore.getClient(client.uid)} />
                ) : null}
            </div>

            <AccountUsers account={client} />

            {IsAllowed("User", "CREATE") ? (
                <Button
                    color="info"
                    variant="contained"
                    className="btn btn-sm"
                    disabled={!!sessionState}
                    onClick={() => {
                        AlertModal({body: (
                            <UserCreateForm
                                account={client} 
                                onSuccess={() => {
                                    getClient(client.uid)
                                    store.AlertStore.Close()
                            }} />
                        )})
                    }}>
                    Create new user
                </Button>
            ) : null}
        </>
    )
}