import React from "react"
import Ajax from "../../../Ajax"
import { store } from "../../../stores/Store"
import { Button, CircularProgress } from "@mui/material"
import { Alert } from "../../.."

export default function AccountQuestionnaireEnableButton ({account, onSuccess = () => {}}) {

    const [submitting, setSubmitting] = React.useState(false)

    const enable = async () => {
        setSubmitting(true)

        if (!store.AppStore.checkJwtRole("account_manager")) {
            Alert({message: "You must be an account manager"})
        }
        
        await Ajax.Questionnaire.EnableAdvisor(account.uid).then(async() => {
            await onSuccess()
        }).catch((response) => {
            Alert({message: response.data?.detail ? response.data.detail : "Failed to enable questionnaires."})
        })
        
        setSubmitting(false)
    }
    
    return (
        <Button
            className="btn btn-sm" 
            variant="contained" 
            color="success"
            disabled={submitting}
            onClick={() => enable()}>
            {submitting ? <CircularProgress color="info" style={{width: "8px", height: "8px"}} /> : null} Enable Questionnaires
        </Button>
    )
}