import React from "react"
import Report from "./Report"
import { observer } from "mobx-react-lite"
import { DisplayValue } from "../../../../.."
import { store } from "../../../../../stores/Store"
import ReportCta from "./ReportCta"
import Field from "../../../../form/fields/Field"


export default observer(function ReportSection () {

    const [showOldVersions, setShowOldVersions] = React.useState(false)

    const {enquiryReports, enquiryReportsCurrentOnly, groupByNationality} = store.ReportStore
    const groupedReports = showOldVersions ? groupByNationality(enquiryReports) : groupByNationality(enquiryReportsCurrentOnly)

    return (
        <div className="detail-section">
            <div className="section-header">
                <div className="header-content">
                    <h4>Reports</h4>

                    {enquiryReports.length > enquiryReportsCurrentOnly.length ? (
                        <Field.Checkbox
                            label="Show old versions"
                            className="field-checkbox-sm"
                            value={showOldVersions}
                            warning={false}
                            onChange={() => setShowOldVersions(!showOldVersions)} />
                    ) : null}
                </div>
            </div>

            {/* <ReportCta /> */}

            {Object.keys(groupedReports).map((nationalityId, i) => (
                <React.Fragment key={i}>
                    <div className="section-subheader">
                        <h5>{DisplayValue("nationality", nationalityId)}</h5>
                    </div>

                    {groupedReports[nationalityId].reverse().map((report, i) => (
                        <div key={i} className="section-table" style={{marginBottom: "10px"}}>
                            <Report key={i} report={report} />
                        </div>
                    ))}
                </React.Fragment>
            ))}

            {(!enquiryReports.length && showOldVersions) || !enquiryReportsCurrentOnly.length && !showOldVersions ? (
                <div className="section-subheader">
                    <h5>There are no reports to show</h5>
                </div>
            ) : null}
        </div>
    )
})


// const ReportCta = observer(() => {

//     const {enquiry} = store.EnquiryStore
//     const {enquiryReportsCurrentOnly} = store.ReportStore
//     const invalidCurrentReport = !!enquiryReportsCurrentOnly.find(r => ["X", "E", "F"].includes(r.fileStatus))
//     const pendingReports = !!enquiryReportsCurrentOnly.find(r => ["R"].includes(r.fileStatus))

//     const serverVersionDate = new Date(store.AppStore.sessionInfo.versionDate)
//     const enquiryLastRun = new Date(enquiry?.lastStarted)
//     const suggestRerun = enquiryLastRun < serverVersionDate
//     const availableReports = []; // CGM 26 Feb 2023 TODO: figure out what to do here.
//     //const availableReports = enquiry.reportOptions.filter(ro => !ro.message)

//     const openTicketForm = () => {
//         var description = `The reports in question can be found at this link:\n${window.location.href}`
//         AlertModal({body: <TicketRequestForm title="Error with reports" description={description} />})
//     }

//     if (store.AppStore.sessionState) return <></>

//     if (enquiry?.status === "DEFINITIVE" && availableReports?.length) {
//         return (
//             <div className="detail-section-clear">
//                 <div className="section-subheader" style={{borderBottom: "none !important"}}>
//                     <h5>You have {availableReports?.length} available report{availableReports?.length > 1 ? "s" : ""}</h5>
//                 </div>
//                 <p></p>
//                 <Button
//                     variant="contained"
//                     className="btn btn-sm"
//                     // onClick={() => AlertModal({body: <ReportRequestForm enquiry={store.EnquiryStore.enquiry} />, width: "600px"})}
//                     >
//                     Create Reports
//                 </Button>
//             </div>
//         )
//     }

//     if (invalidCurrentReport && enquiry.status !== "DEFINITIVE") {
//         return (
//             <div className="detail-section-clear">
//                 <div className="section-subheader" style={{borderBottom: "none !important"}}>
//                     <h5>Your reports have become invalid, re-run the enquiry to generate new ones.</h5>
//                 </div>
//                 <p></p>
//                 <Button className="btn btn-sm" variant="contained" onClick={() => store.EnquiryStore.rerun()}>Re-run enquiry</Button>
//             </div>
//         )
//     }

//     if (invalidCurrentReport && !suggestRerun) {
//         return (
//             <div className="detail-section-clear">
//                 <h5 className="warning">You have reports which have errors or have failed. This enquiry has not been run since the last server update, re-running the enquiry may fix the problem.</h5>
//                 <br/>
//                 <Button className="btn btn-sm" variant="contained" onClick={() => store.EnquiryStore.rerun()}>Re-run enquiry</Button>
//             </div>
//         )
//     }

//     if (invalidCurrentReport && !availableReports?.length && !pendingReports) {
//         return (
//             <div className="detail-section-clear">
//                 <h5 className="warning">You have reports which have errors or have failed. Notify support about the problem so we can get it fixed as soon as possible.</h5>
//                 <br/>
//                 <Button className="btn btn-sm" variant="contained" onClick={() => openTicketForm()}>Contact support</Button>
//             </div>
//         )
//     }

//     return <></>
// })